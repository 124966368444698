import { get } from "@/util/requestUtils"

export function getSearchTypeList(success) {
  return get("/search/public-api/content/type", {}, success)
}

export function getHotWordList(params, success) {
  return get("/search/public-api/hot-word/list", params, success)
}

export function getSearchContentList(params, success) {
  return get("/search/public-api/content", params, success)
}
