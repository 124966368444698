import {get, post} from "@/util/requestUtils";

// 获取分类列表
export const liveList = (params, success) => {
    return get("/live/public-api/channel/list", params, success)
}

// 获取频道信息
export const getChannel = (params, success) => {
    return get("/live/public-api/channel", params, success)
}

export function getChannelListByIds(params, success) {
  return get("/live/public-api/channel/list/by-ids", params, success)
}

export function subscribeChannel(data, success) {
  return post("/live/auth-api/subscribe", data, success)
}
