import axios from "axios";
import { getToken } from "@/util/tokenUtils";
import { error } from "@/util/tipsUtils";
import qs from "qs"
import {loginOut} from "@/api/login";
import router from "@/router";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
  paramsSerializer: function(params) {
    return qs.stringify(params, { arrayFormat: "repeat" })
  }
});

// request interceptor
service.interceptors.request.use(
  config => {
    // if (config.method === "get") {
    // }
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ["X-Token"] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const code = response.status;
    if (code < 200 || code > 300) {
      error(response.message);
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  e => {
    let code = 0;
    try {
      code = e.response.status;
    } catch (e) {
      if (e.toString().indexOf("Error: timeout") !== -1) {
        error("网络请求超时");
        return Promise.reject(e);
      }
      if (e.toString().indexOf("Error: Network Error") !== -1) {
        error("网络请求错误");
        return Promise.reject(e);
      }
    }
    if (code === 401) {
      if (e.config.url.indexOf("/public-api") === -1) {
        error("认证失败");
        loginOut()
        router.push("/login")
      }
    } else {
      const errorMsg = e.response.data.msg || e.response.data.message || e.response.data.error || e.response.data;
      if (errorMsg !== undefined) {
        if (code === 502) {
          console.log(errorMsg)
          error("Bad Gateway", true);
        } else {
          error(errorMsg);
        }
      }
    }
    return Promise.reject(e);
  }
);

export default service;
