import {createRouter, createWebHistory} from "vue-router";
import Layout from "../components/Layout.vue";
import Index from "../views/index/index"

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: "/index",
  },
  {
    path: "/index",
    name: "indexLayout",
    component: Layout,
    children: [{
      path: "",
      name: "index",
      component: Index
    }]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Index")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue")
  },
  {
    path: "/learn/payment",
    name: "learnPayment",
    component: () => import("../views/learn/payment/index"),
  },
  {
    path: "/learn/payment/confirm",
    name: "learnPaymentConfirm",
    component: () => import("../views/learn/payment/confirm/index"),
  },
  {
    path: "/forget/pwd",
    name: "forgetPwd",
    component: () => import("../components/ForgetPwd")
  },
  {
    path: "/agreement/:type",
    name: "agreement",
    component: () => import("../views/agreement/index"),
  },
  {
    path: "/certificate/download",
    name: "certificateDownload",
    component: () => import("../views/learn/certificate/download/index"),
  },
  {
    path: "/search",
    name: "search",
    component: Layout,
    children: [
      {
        path: "",
        name: "searchIndex",
        component: () => import("../views/search/index"),
      }
    ]
  },
  {
    path: "/learn",
    name: "learn",
    component: Layout,
    children: [
      {
        path: "buyconfirm",
        name: "learnBuyConfirm",
        component: () => import("../views/learn/buyconfirm/index"),
      },
      {
        path: "topic",
        name: "learnTopic",
        component: () => import("../views/learn/topic/index"),
      },
      {
        path: "topic/detail",
        name: "learnTopicDetail",
        component: () => import("../views/learn/topic/detail/index"),
      },
      {
        path: "map",
        name: "learnMap",
        component: () => import("../views/learn/map/index"),
      },
      {
        path: "detail",
        name: "learnDetail",
        component: () => import("../views/learn/detail/index"),
      },
      {
        path: "list",
        name: "learnList",
        component: () => import("../views/learn/list/index"),
      },
      {
        path: "",
        name: "learnIndex",
        component: () => import("../views/learn/index"),
      }
    ]
  },
  {
    path: "/live",
    name: "live",
    component: Layout,
    children: [
      {
        path: "list",
        name: "liveList",
        redirect: "/live",
      },
      {
        path: "",
        name: "liveIndex",
        component: () => import("../views/live/list"),
      },
      {
        path: "detail",
        name: "liveDetail",
        component: () => import("../views/live/detail/index"),
      }
    ]
  },
  {
    path: "/live/play",
    name: "livePlay",
    component: () => import("../views/live/detail/play"),
  },
  {
    path: "/news",
    name: "news",
    component: Layout,
    children: [
      {
        path: "",
        name: "newsIndex",
        component: () => import("../views/news/index"),
      },
      {
        path: "detail",
        name: "newsDetail",
        component: () => import("../views/news/detail"),
      }
    ]
  },
  {
    path: "/exam/paper",
    name: "examPaper",
    component: () => import("../views/exam/paper"),
  },
  {
    path: "/exam/paper/detail",
    name: "examPaperDetail",
    component: () => import("../views/exam/paper/detail"),
  },
  {
    path: "/exam",
    name: "exam",
    component: Layout,
    children: [
      {
        path: "detail",
        name: "examDetail",
        component: () => import("../views/exam/detail"),
      },
      {
        path: "list",
        name: "examList",
        component: () => import("../views/exam/list"),
      },
      {
        path: "",
        name: "examIndex",
        component: () => import("../views/exam/index"),
      }
    ]
  },
  {
    path: "/article",
    name: "article",
    component: Layout,
    children: [
      {
        path: "",
        name: "articleIndex",
        component: () => import("../views/article/index"),
      },
      {
        path: "detail",
        name: "articleDetail",
        component: () => import("../views/article/detail"),
      }
    ]
  },
  {
    path: "/ask",
    name: "ask",
    component: Layout,
    children: [
      {
        path: "",
        name: "askIndex",
        component: () => import("../views/ask/index"),
      },
      {
        path: "question",
        name: "askQuestion",
        component: () => import("../views/ask/question/index"),
      }
    ]
  },
  {
    path: "/message",
    name: "message",
    component: Layout,
    children: [
      {
        path: "",
        name: "memberMessage",
        component: () => import("../views/message"),
      },
      {
        path: "notice",
        name: "memberNotice",
        component: () => import("../views/message"),
      },
      {
        path: "like",
        name: "memberMessageLike",
        component: () => import("../views/message"),
      },
      {
        path: "favorite",
        name: "memberMessageFavorite",
        component: () => import("../views/message"),
      },
      {
        path: "comment",
        name: "memberMessageComment",
        component: () => import("../views/message"),
      },
      {
        path: "fans",
        name: "memberMessageFans",
        component: () => import("../views/message"),
      },
      {
        path: "private-letter",
        name: "memberPrivateLetter",
        component: () => import("../views/message"),
      }
    ]
  },
  {
    path: "/member",
    name: "member",
    component: Layout,
    children: [
      {
        path: "personal",
        name: "memberPersonal",
        component: () => import("../views/member/personal/index"),
      },
      {
        path: "detail",
        name: "memberDetail",
        component: () => import("../views/member/detail/index"),
      },
      {
        path: "favorites",
        name: "memberFavorites",
        component: () => import("../views/member/favorites/index"),
      },
      {
        path: "learn-record",
        name: "memberLearnRecord",
        component: () => import("../views/member/learn-record/index"),
      },
      {
        path: "setting",
        name: "memberSetting",
        component: () => import("../views/member/setting/index"),
      },
      {
        path: "comment",
        name: "memberComment",
        component: () => import("../views/member/comment/index"),
      },
      {
        path: "fans",
        name: "memberFans",
        component: () => import("../views/member/fans/index"),
      },
      {
        path: "follow",
        name: "memberFollow",
        component: () => import("../views/member/follow/index"),
      },
      {
        path: "ask",
        name: "memberAsk",
        component: () => import("../views/member/ask/index"),
      },
      {
        path: "circle",
        name: "memberCircle",
        component: () => import("../views/member/circle/index"),
      },
      {
        path: "resource",
        name: "memberResource",
        component: () => import("../views/member/resource/index"),
      },
      {
        path: "article",
        name: "memberArticle",
        component: () => import("../views/member/article/index"),
      },
      {
        path: "point",
        name: "memberPoint",
        component: () => import("../views/member/point/index"),
      },
      {
        path: "exam/sign-up",
        name: "memberExamSignUp",
        component: () => import("../views/member/exam/sign-up/index"),
      },
      {
        path: "exam/record",
        name: "memberExamRecord",
        component: () => import("../views/member/exam/record/index"),
      },
      {
        path: "exam/wrong-question",
        name: "memberExamWrongQuestion",
        component: () => import("../views/member/exam/wrong/index"),
      },
      {
        path: "certificate",
        name: "memberCertificate",
        component: () => import("../views/member/certificate/index"),
      },
    ]
  },
  {
    path: "/circle",
    name: "circle",
    component: Layout,
    children: [
      {
        path: "list",
        name: "circleList",
        redirect: "/circle",
      },
      {
        path: "",
        name: "circleIndex",
        component: () => import("../views/circle/index"),
      },
      {
        path: "detail",
        name: "circleDetail",
        component: () => import("../views/circle/detail"),
      }
    ]
  },
  {
    path: "/resource",
    name: "resource",
    component: Layout,
    children: [
      {
        path: "list",
        name: "resourceList",
        redirect: "/resource",
      },
      {
        path: "",
        name: "resourceIndex",
        component: () => import("../views/resource/list"),
      },
      {
        path: "detail",
        name: "resourceDetail",
        component: () => import("../views/resource/detail"),
      },
      {
        path: "edit",
        name: "resourceEdit",
        component: () => import("../views/resource/edit"),
      }
    ]
  },
  {
    path: "/announcement",
    name: "announcement",
    component: Layout,
    children: [
      {
        path: "",
        name: "announcementIndex",
        component: () => import("../views/announcement"),
      },
      {
        path: "detail",
        name: "announcementDetail",
        component: () => import("../views/announcement/detail"),
      }
    ]
  },
];

export const asyncRoutes = [
]

let routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
};
let router = createRouter(routerOptions);
export default router;
