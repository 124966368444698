<template>
  <div class="nav-menu sub-nav-menu" :class="{'position-fixed': false}">
    <div class="nav-menu-main">
      <div class="nav-menu-main-box">
        <div class="nav-item">
          <router-link :to="{path: '/learn'}">
            课程
          </router-link>
          <learn-nav-menu/>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/live'}">
            直播
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/exam'}">
            考试
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/news'}">
            资讯
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/article'}">
            文章
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/ask'}">
            问答
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/circle'}">
            社区
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/resource'}">
            知识库
          </router-link>
        </div>
        <div class="nav-item">
          <router-link :to="{path: '/announcement'}">
            公告
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnNavMenu from "@/views/learn/navMenu";
export default {
  name: "NavMenu",
  components: {
    LearnNavMenu
  },
  setup() {
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.position-fixed {
  position: fixed;
  z-index: 2001;
  top: 60px;
  left: 0;
}
.nav-menu {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  line-height: 60px;
  .nav-menu-main {
    //max-width: 1400px;
    //min-width: 960px;
    display: inline-block;
    margin: 0 auto;
    padding: 0 10px;
    color: #ffffff;
    background: #000000;
    height: 60px;
    .nav-menu-main-box {
      display: inline-block;
      margin: 0;
      height: 100%;
      .nav-item {
        display: inline-block;
        a {
          float: left;
          display: inline-block;
          padding: 0 10px;
          color: inherit;
          cursor: pointer;
          &:hover {
            color: $--color-primary;
          }
        }
        &:hover {
          .sub-nav-menu {
            display: block;
          }
        }
      }
    }
  }
}
</style>
