import { ElNotification, ElMessageBox, ElLoading } from "element-plus";

export function error(tips, dangerouslyUseHTMLString) {
  ElNotification({
    title: "错误",
    message: tips,
    type: "error",
    dangerouslyUseHTMLString: dangerouslyUseHTMLString || false
  });
}

export function info(tips) {
  ElNotification({
    title: "消息",
    message: tips,
    type: "info"
  });
}

export function success(tips) {
  ElNotification({
    title: "成功",
    message: tips,
    type: "success"
  });
}

export function warning(tips) {
  ElNotification({
    title: "成功",
    message: tips,
    type: "warning"
  });
}

export function confirm(tips, title, submit, cancel) {
  ElMessageBox.confirm(tips, title, {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning"
  }).then(() => {
    submit && submit()
  }).catch(() => {
    cancel && cancel()
  })
}

export function loading(tips) {
  return ElLoading.service({
    lock: true,
    text: tips,
    spinner: "el-icon-loading",
    background: "rgba(f, f, f, 0.7)"
  });
}
