import request from "./remoteUtils";
import { error } from "./tipsUtils";

export function get(url, params, successCallback) {
  return request({
    url: url,
    method: "get",
    params: params
  }).then(res => {
    if (res && res.code === 0) {
      successCallback && successCallback(res.data);
    } else {
      error(res.msg);
    }
  });
}

export function post(url, data, successCallback) {
  return request({
    url: url,
    method: "post",
    data
  }).then(res => {
    if (res && res.code === 0) {
      successCallback && successCallback(res.data);
    } else {
      error(res.msg);
    }
  });
}

export function put(url, data, successCallback) {
  return request({
    url: url,
    method: "put",
    data
  }).then(res => {
    if (res && res.code === 0) {
      successCallback && successCallback(res.data);
    } else {
      error(res.msg);
    }
  });
}

export function del(url, data, successCallback) {
  return request({
    url: url,
    method: "delete",
    data
  }).then(res => {
    if (!res || res && res.code === 0) {
      successCallback && successCallback(res.data);
    } else {
      error(res.msg);
    }
  });
}
