import { get } from "@/util/requestUtils"

// 查询推荐课程
export function getRecommendLesson(params, success) {
  return get("/learn/public-api/lesson/recommend", params, success)
}

// 查询分类热门课程
export function getHotLesson(params, success) {
  return get("/learn/public-api/lesson/hot", params, success)
}

import * as lessonCategoryApi from "@/api/learn/category";
import * as articleCategoryApi from "@/api/content/category";
import * as askCategoryApi from "@/api/ask/category";
import * as circleCategoryApi from "@/api/circle/category";
import * as liveCategoryApi from "@/api/live/category";
import * as resourceCategoryApi from "@/api/resource/category";
import storageUtils from "@/util/storageUtils";
export async function getCategory(success) {
  // 10分钟过期
  const expire = 600000
  if (storageUtils.getJsonExpire("learn")) {
    const toTree = storageUtils.getJsonExpire("learn");
    success && success({value: 0, type: "learn", label: "课程", children: toTree})
  } else {
    try {
      // 获取缓存的目录
      await lessonCategoryApi.findCategoryList(0, true, (res) => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("learn", toTree, expire)
        success && success({value: 0, type: "learn", label: "课程", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取课程目录出错")
    }
  }
  if (storageUtils.getJsonExpire("live")) {
    const toTree = storageUtils.getJsonExpire("live");
    success && success({value: 1, type: "live", label: "直播", children: toTree})
  } else {
    try {
      await liveCategoryApi.findCategoryList(0, true, res => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("live", toTree, expire)
        success && success({value: 1, type: "live", label: "直播", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取直播目录出错")
    }
  }
  if (storageUtils.getJsonExpire("article")) {
    const toTree = storageUtils.getJsonExpire("article");
    success && success({value: 2, type: "article", label: "文章", children: toTree})
  } else {
    try {
      await articleCategoryApi.findCategoryList(0, true, res => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("article", toTree, expire)
        success && success({value: 2, type: "article", label: "文章", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取文章目录出错")
    }
  }
  if (storageUtils.getJsonExpire("ask")) {
    const toTree = storageUtils.getJsonExpire("ask");
    success && success({value: 3, type: "ask", label: "问答", children: toTree})
  } else {
    try {
      await askCategoryApi.findCategoryList(0, true, res => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("ask", toTree, expire)
        success && success({value: 3, type: "ask", label: "问答", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取问答目录出错")
    }
  }
  if (storageUtils.getJsonExpire("circle")) {
    const toTree = storageUtils.getJsonExpire("circle");
    success && success({value: 4, type: "circle", label: "社区", children: toTree})
  } else {
    try {
      await circleCategoryApi.findCategoryList(0, true, res => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("circle", toTree, expire)
        success && success({value: 4, type: "circle", label: "社区", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取社区目录出错")
    }
  }
  if (storageUtils.getJsonExpire("resource")) {
    const toTree = storageUtils.getJsonExpire("resource");
    success && success({value: 5, type: "resource", label: "知识", children: toTree})
  } else {
    try {
      await resourceCategoryApi.findCategoryList(0, true, res => {
        const toTree = lessonCategoryApi.toTree(res);
        storageUtils.setJsonExpire("resource", toTree, expire)
        success && success({value: 5, type: "resource", label: "知识", children: toTree})
      })
    } catch (error) {
      // 处理异常，例如显示错误信息
      console.log("获取社区目录出错")
    }
  }
}
