import {del, get, post, put} from "@/util/requestUtils";

// 获取社区列表
export const getCircleList = (params, success) => {
  return get("/circle/public-api/circle/list", params, success)
}

// 获取热门社区列表
export const getCircleHotList = (params, success) => {
  return get("/circle/public-api/circle/hot/list", params, success)
}

// 获取社区列表
export const getHotCircleList = (params, success) => {
  return get("/circle/public-api/circle/list", params, success)
}

// 添加社区
export function createCircle(data, success) {
  return post("/circle/auth-api/circle", data, success)
}

export function updateCircle(data, success) {
  return put("/circle/auth-api/circle", data, success)
}

// 获取分类列表
export const getCircleCategoryList = (params, success) => {
  return get("/circle/public-api/category/list", params, success)
}

// 社区详情
export const getCircle = (params, success) => {
  return get("/circle/public-api/circle", params, success)
}

// 会员社区数量
export function countMemberCircle(success) {
  return get("/circle/public-api/circle/member/count", {}, success)
}

// 加入社区
export const joinCircle = (data, success) => {
  return post("/circle/auth-api/member", data, success)
}

// 退出社区
export const exitCircle = (data, success) => {
  return del("/circle/auth-api/member", data, success)
}

// 社区会员数量
export function getCircleMemberNum(id, success) {
  return get("/circle/public-api/member/count", {circleId: id}, success)
}

// 会员加入社区的记录
export function getCircleMember(id, success) {
  return get("/circle/public-api/member", {circleId: id}, success)
}

// 会员创建的社区列表
export function getMemberCircleList(params, success) {
  return get("/circle/auth-api/member/circle/list", params, success)
}

// 会员加入的社区列表
export function getMemberJoinCircleList(params, success) {
  return get("/circle/auth-api/member/join/circle/list", params, success)
}

// 解散社区
export const removeCircle = (data, success) => {
  return del("/circle/auth-api/circle", data, success)
}

export function getListByIds(params, success) {
  return get("/circle/public-api/circle/list/by-ids", params, success)
}
