import {getToken, refreshToken, removeToken} from "@/util/tokenUtils";
import {getUser} from "@/util/userUtils";
import {error} from "@/util/tipsUtils";
import router from "./index"
import {sendVisitLog} from "@/util/buriedPointUtils";

const whiteList = ["/login", "/work-we-chat", "/ding-talk", '/agreement/service', '/agreement/privacy', '/register', '/forget/pwd'];
router.beforeEach(async (to, from, next) => {
  // document.title = getPageTitle(to.meta.title)
  refreshToken()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/index" });
    } else {
      const userInfo = getUser()
      if (userInfo) {
        next();
      } else {
        try {
          next();
        } catch (e) {
          removeToken();
          error(e || "Has Error");
          next({ path: "/login" });
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      removeToken();
      next("/login");
    }
  }
})
router.afterEach(() => {
  sendVisitLog()
})
