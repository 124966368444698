import { uuid } from "vue-uuid";
import { saveVisitTracking } from "@/api/visittracking"
import storage from "@/util/storageUtils";

// sessionId过期时间 单位：分钟
const expirationTime = 10

// 获取UUID
export function getUuid() {
  return uuid.v1().replaceAll("-", "");
}

// 生成sessionId
export function generateSessionId() {
  storage.set("SESSION_ID", getUuid());
}

// 生成UUID
export function generateUuidId() {
  const uuid = storage.get("MEMBER_UUID")
  if (!uuid) {
    storage.set("MEMBER_UUID", getUuid());
  }
}

// 更新SessionId
export const refreshSessionId = () => {
  const sessionId = storage.get("SESSION_ID")
  if (!sessionId) {
    // 生成SessionId
    generateSessionId()
  }
  clearTimeout(window.refreshSessionIdEvent)
  window.refreshSessionIdEvent = setTimeout(() => {
    // 生成SessionId
    generateSessionId()
    // 定时更新
    refreshSessionId()
  }, 1000 * 60 * expirationTime + 100)
}

function formatDate(date){
  let dt = new Date(date);
  let y = dt.getFullYear();
  let m = dt.getMonth() + 1;
  let d = dt.getDate();
  return `${y}-${m}-${d}`
}

// 每访问一个页面都发送访问日志
export const sendVisitLog = () => {
  const lastUpdateTime = storage.get("lastUpdateTime")
  if (lastUpdateTime && formatDate(new Date()) !== lastUpdateTime) {
    storage.remove("MEMBER_UUID")
  }
  storage.set("lastUpdateTime", formatDate(new Date()))
  // 生成UUID
  generateUuidId()
  // 更新sessionId
  refreshSessionId()
  const data = {
    sessionId: storage.get("SESSION_ID"),
    uuid: storage.get("MEMBER_UUID"),
    url: window.location.href,
    ipAddress: storage.get("ipAddress"),
    ipCityName: storage.get("ipCityName") || "",
    channel: "web"
  }
  if (!data.ipAddress) {
    return;
  }
  saveVisitTracking(data, () => {
  }).then(() => {
  })
}

