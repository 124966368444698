import { get } from "../../util/requestUtils"

export function findList(params, success) {
  return get("/content/public-api/news/list", params, success)
}

export function getNews(id, success) {
  return get("/content/public-api/news", {id: id}, success)
}

export function findTopList(params, success) {
  return get("/content/public-api/news/top/list", params, success)
}

export function findRecommendList(params, success) {
  return get("/content/public-api/news/recommend/list", params, success)
}

export function getListByIds(params, success) {
  return get("/content/public-api/news/list/by-ids", params, success)
}
