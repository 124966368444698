<template>
  <div class="resource-content-box">
    <div class="module-header">
      <div class="head">
        <h2 class="head-title">
          <div class="title">
            <span style="margin-right: 5px;">
              <svg-icon name="resource"/>
            </span>
            <router-link :to="{path: 'resource'}">知识库</router-link>
          </div>
        </h2>
      </div>
    </div>
    <div class="content-list" v-loading="listLoading">
      <resource-item :item-list="itemList"></resource-item>
    </div>
  </div>
</template>

<script>
import {ref} from "vue"
import {getResourceList} from "@/api/resource"
import ResourceItem from "@/views/resource/resourceItem";
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "ResourceIndex",
  components: {
    SvgIcon,
    ResourceItem
  },
  setup() {
    const itemList = ref([])
    const param = ref({
      cid: 0,
      current: 1,
      size: 20,
      type: ""
    })
    const total = ref(0)
    const listLoading = ref(true)
    const load = function() {
      listLoading.value = true
      getResourceList(param.value, res => {
        itemList.value = res.list
        total.value = res.total
        listLoading.value = false
      }).catch(() => {
        listLoading.value = false
      })
    }
    load()
    return {
      itemList,
      param,
      total,
      listLoading,
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-content-box {
  margin: 0 0 20px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .module-header {
    display: flex;
    .head {
      line-height: 40px;
      height: 40px;
      display: inline-block;
      &:before {
        content: " ";
        display: table;
      }
      .head-title {
        margin-top: 20px;
        margin-left: 10px;
        font-size: 20px;
        float: left;
        margin-right: 12px;
        transform: translateY(-50%);
        color: #222;
        a {
          color: #222;
        }
      }
      &:after {
        clear: both;
        content: " ";
        display: table;
      }
    }
  }
  .content-list {
    display: block;
    border-radius: 4px;
    min-height: 180px;
    ::v-deep .content-item {
      width: calc(50% - 20px);
      float: left;
      margin: 10px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
