import { get } from "@/util/requestUtils"

export function findCategoryList(id, fetchAll, success) {
  return new Promise((resolve)=> {
    const s = get("/resource/public-api/category/list", { id: id, fetchAll: fetchAll }, success)
    resolve(s)
  })
}

export function toTree(data) {
  const resData = data
  const tree = []
  for (let i = 0; i < resData.length; i++) {
    if (resData[i].pid === 0) {
      const obj = {
        value: resData[i].id,
        label: resData[i].name
      }
      tree.push(obj)
      resData.splice(i, 1)
      i--
    }
  }
  function run(chiArr) {
    if (resData.length !== 0 && chiArr) {
      for (let i = 0; i < chiArr.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (chiArr[i].value === resData[j].pid) {
            const obj = {
              value: resData[j].id,
              label: resData[j].name
            }
            if (!chiArr[i].children) {
              chiArr[i].children = []
            }
            chiArr[i].children.push(obj)
            resData.splice(j, 1)
            j--
          }
        }
        run(chiArr[i].children)
      }
    }
  }
  run(tree)
  return tree
}
