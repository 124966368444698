import {del, get, post, put} from "@/util/requestUtils"
import {getUser, setUser} from "@/util/userUtils";
import {getCurrentUser} from "../login";
import {setAuthorities} from "@/util/authorityUtils";
import router from "@/router";

// 跳转方法
export function gotoMemberDetail(id) {
  router.push({path: "/member/detail", query: {id: id}})
}

export function registerMember(data, callback) {
  return post("/member/public-api/register", data, callback)
}

export function registerMemberByMobile(data, callback) {
  return post("/member/public-api/register/mobile", data, callback)
}

export function updateAvatar(data, success) {
  put("/member/auth-api/update/avatar", data, success)
}

export function updateName(data, success) {
  put("/member/auth-api/update/name", data, success)
}

export function updateMobile(data, success) {
  put("/member/auth-api/update/mobile", data, success)
}

export function updateEmail(data, success) {
  put("/member/auth-api/update/email", data, success)
}

export function updatePassword(data, success) {
  put("/member/auth-api/update/password", data, success)
}

export function getMemberInfo(success) {
  const m = getUser()
  if (!m) {
    return;
  }
  const params = { mobile: m.mobile || m.username || m.email }
  return get("/member/auth-api/by-mobile", params, success)
}

export function getMemberByMobile(mobile, callback) {
  const params = { mobile: mobile };
  return get("/member/auth-api/by-mobile", params, callback);
}

export function getMemberById(id, callback) {
  const params = { id: id };
  return get("/member/auth-api/by-id", params, callback);
}

export function getAuthMemberList(params, callback) {
  return get("/member/auth-api/list", params, callback);
}

export function getMember() {
  return new Promise((resolve, reject) => {
    getCurrentUser(res => {
      // 保存权限信息
      setAuthorities(res);
      // 获取用户信息
      getMemberByMobile(res.username, user => {
        setUser(user);
        return resolve(user);
      }).catch(e => {
        reject(e)
      });
    }).catch(e => {
      reject(e)
    })
  });
}

export function checkIn(success) {
  return post("/member/auth-api/check-in", {}, success)
}

export function getCheckIn(success) {
  return get("/member/public-api/check-in", {}, success)
}

export function isFollowMember(followMemberId, success) {
  return get("/member/auth-api/follow", {followMemberId: followMemberId}, success)
}

export function followMember(followMemberId, success) {
  return post("/member/auth-api/follow", {followMemberId: followMemberId}, success)
}

export function unfollowMember(followMemberId, success) {
  return del("/member/auth-api/follow", {followMemberId: followMemberId}, success)
}

export function followMemberCount(followMemberId, success) {
  return get("/member/public-api/follow/member/count", {followMemberId: followMemberId}, success)
}

  export function getFollowMemberList(param, success) {
  return get("/member/auth-api/follow/list", param, success)
}

export function getFollowFansMemberList(param, success) {
  return get("/member/auth-api/follow/fans/list", param, success)
}

export function getListByIds(params, success) {
  return get("/member/public-api/by-ids", params, success)
}

// 获取密码验证码
export function getPwdAuthCode(username, callback) {
  return post("/member/public-api/pwd/send/auth-code", { username: username }, callback);
}
// 校验密码验证码
export function checkPwdAuthCode(data, callback) {
  return post("/member/public-api/pwd/check/auth-code", data, callback);
}
// 重置密码
export function resetPwd(data, callback) {
  return put("/member/public-api/pwd/reset", data, callback);
}
