import Cookies from "js-cookie";
import { refreshAccessToken } from "@/api/login";
import storage from "@/util/storageUtils";
import router from "@/router";
const TokenKey = "web_token";
const RefreshTokenKey = "web_refresh_token";
const RefreshTokenKeyExpireDate = "web_refresh_token_expire_date";

export function getExpireTime() {
  // token刷新时间
  return 2000000;
}

export function getRefreshTokenKeyExpireDate() {
  return storage.get(RefreshTokenKeyExpireDate);
}

export function getToken() {
  // 用户登录token
  return storage.getExpire(TokenKey);
}

export function refreshAccessTokenSetTimeout(timestamp) {
  clearTimeout(window.interval);
  window.interval = setTimeout(() => {
    // eslint-disable-next-line no-use-before-define
    refreshTokenMethod();
  }, timestamp);
}

export function setToken(data) {
  const expireData = new Date().getTime() + data.accessToken.expiresIn * 1000;
  storage.set(RefreshTokenKeyExpireDate, expireData);
  Cookies.set(RefreshTokenKey, data.refreshToken);
  refreshAccessTokenSetTimeout(getExpireTime());
  return storage.setExpire(TokenKey, data.accessToken.value, expireData);
}

export function removeToken() {
  Cookies.remove(RefreshTokenKey);
  storage.remove(RefreshTokenKeyExpireDate);
  localStorage.clear();
  return storage.remove(TokenKey);
}

export function refreshTokenMethod() {
  const refreshToken = Cookies.get(RefreshTokenKey);
  if (!refreshToken) {
    router.push({ path: "/login" });
    return;
  }
  refreshAccessToken({ refreshToken: refreshToken }, res => {
    const accessToken = { expiresIn: res.expires_in, value: res.access_token };
    const refreshToken = res.refresh_token;
    refreshAccessTokenSetTimeout(getExpireTime());
    setToken({ accessToken: accessToken, refreshToken: refreshToken });
  }).catch(() => {});
}

// 刷新Token
export function refreshToken() {
  const tokenKeyExpireDate = getRefreshTokenKeyExpireDate()
  if (tokenKeyExpireDate) {
    const resetTimer = Math.floor(Number(tokenKeyExpireDate) - new Date().getTime())
    if (resetTimer <= 1200000) {
      refreshAccessTokenSetTimeout(15)
    } else {
      refreshAccessTokenSetTimeout(getExpireTime())
    }
  }
}
