<template>
  <div>
    <div class="header-container">
      <div class="header-contain">
        <div class="container">
          <div class="header-box">
            <div class="header-logo">
              <a href="/" title="cloud learning" class="a">
                米度Family
<!--                <img class="logo" src="../assets/header/logo.svg"/>-->
              </a>
            </div>
            <div class="header-nav-menu">
              <nav-menu/>
            </div>
<!--              <div class="header-search">-->
<!--                <div class="header-search-input">-->
<!--                  <el-input @blur="hotSearchListShow = false" @focus="hotSearchListShow = true" v-model="keyword" :placeholder="defaultKeyword" autoComplete="off">-->
<!--                    <template #append>-->
<!--                      <el-button :icon="Search" @click="search">搜索</el-button>-->
<!--                    </template>-->
<!--                  </el-input>-->
<!--                </div>-->
<!--                <div class="search-list" @mousemove="hotSearchListMove = true" @mouseout="hotSearchListMove = false" v-if="(hotSearchListShow || hotSearchListMove) && hotSearchList && hotSearchList.length">-->
<!--                  <div class="hot-search-list" v-loading="hotSearchListLoading">-->
<!--                    <h2 class="title">热门搜索</h2>-->
<!--                    <div @click.stop="hotSearch(item)" class="hot-item" v-for="(item, index) in hotSearchList" :key="item.id">-->
<!--                      <span class="order">{{index + 1}}</span>-->
<!--                      <span class="hot-word">{{item.name}}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            <div class="header-user-center">
              <div class="header-user-item" @click="search">
                <span class="icon-title search-btn">
                  <el-icon size="20"><Search /></el-icon>
                  <span style="margin-left: 5px; vertical-align: super;">搜索</span>
                </span>
              </div>
              <div class="header-user-item" v-if="isLogin">
                <el-dropdown class="dropdown">
                  <a>
                    <span class="icon-title">
                      <el-icon size="20"><Notification /></el-icon>
                      <span style="margin-left: 5px; vertical-align: super;">消息</span>
                    </span>
                  </a>
                  <template #dropdown>
                    <el-dropdown-menu :style="{'display:none;': !isLogin}">
                      <router-link :to="{path: '/message/notice'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          通知
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/message/like'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          点赞
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/message/favorite'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          收藏
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/message/comment'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          评论
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/message/fans'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          粉丝
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/message/private-letter'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          私信
                        </el-dropdown-item>
                      </router-link>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div class="header-user-item" v-if="!isLogin">
                <a href="/register">
                  <span class="icon-title">注册</span>
                </a>
              </div>
              <div class="header-line" v-if="!isLogin">|</div>
              <div class="header-user-item">
                <el-dropdown class="dropdown" v-if="isLogin">
                  <a style="display: flex;">
                    <div style="line-height: 76px;">
                      <el-avatar v-if="isLogin && member.avatar" :size="24">
                        <img :src="member.avatar"/>
                      </el-avatar>
                    </div>
                    <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis; display: inline-block;max-width: 120px; margin-left: 5px; vertical-align: super;">{{member.name}}</span>
                  </a>
                  <template #dropdown>
                    <el-dropdown-menu :style="{'display:none;': !isLogin}">
                      <router-link :to="{path: '/member/detail', query: {id: member.id}}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          我的主页
                        </el-dropdown-item>
                      </router-link>
                      <router-link :to="{path: '/member/personal'}" class="color-black">
                        <el-dropdown-item class="menu-item">
                          个人中心
                        </el-dropdown-item>
                      </router-link>
                      <el-dropdown-item divided @click="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <a v-else @click="showLogin">
                  <span class="icon-title" style="white-space: nowrap;text-overflow: ellipsis;">{{member.name}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {inject, ref} from "vue"
  import router from "../router"
  import {getToken} from "@/util/tokenUtils"
  import {getUser} from "@/util/userUtils";
  import {loginOut} from "@/api/login";
  import {getHotWordList} from "@/api/search";
  import NavMenu from "./NavMenu";

  export default {
    name: "CustomHeader",
    components: {
      NavMenu
    },
    setup(){
      const showLoginFlag = inject("showLogin")
      const isLogin = ref(!!getToken())
      const categoryList = ref([])
      const defaultKeyword = ref("搜索感兴趣的内容")
      const keyword = ref("")
      let member;
      const loginMember = getUser();
      if (loginMember && getToken()) {
        member = ref(loginMember);
      } else {
        member = ref({
          name: "登录",
          avatar: ""
        })
      }
      const showLogin = function() {
        // 没登录显示登陆框
        if (!getToken()) {
          showLoginFlag.value = true
          isLogin.value = false
        } else {
          showLoginFlag.value = false
          isLogin.value = true
        }
      }
      const logout = function() {
        loginOut();
        isLogin.value = false
        member.value = {name: "登录", avatar: ""}
        router.push({path: "/index"});
      }
      const search = function() {
        let key = keyword.value
        if (!key && "搜索感兴趣的内容" !== defaultKeyword.value) {
          // key = defaultKeyword.value
        }
        // router.push({path: "/search", query:{ keyword: key}});
        router.push({path: "/search"});
      }
      const hotParams = {
        current: 1,
        size: 10
      }
      const hotSearchList = ref([])
      const hotSearchListLoading = ref(true)
      const hotSearchListShow = ref(false)
      const hotSearchListMove = ref(false)
      let i = 0;
      getHotWordList(hotParams, res => {
        hotSearchListLoading.value = false
        hotSearchList.value = res.list
        if (res.list && res.list.length) {
          defaultKeyword.value = res.list[0].name
        }
        if (res.list && res.list.length) {
          setInterval(() => {
            i++
            if (i >= res.list.length) {
              i = 0
            }
            defaultKeyword.value = res.list[i].name
          }, 8000)
        }
      })
      const hotSearch = (item) => {
        hotSearchListMove.value = false
        router.push({path: "/search", query:{ keyword: item.name}});
      }
      return{
        isLogin,
        member,
        categoryList,
        defaultKeyword,
        keyword,
        showLogin,
        logout,
        search,
        hotSearchList,
        hotSearchListLoading,
        hotSearchListShow,
        hotSearchListMove,
        hotSearch
      }
    }
  }
</script>

<style lang="scss">
  .hidden {
    display: none;
  }
  .header-container, .header-fixed {
    width: 100%;
    position: absolute;
    z-index: 2002;
    top: 0;
    box-sizing: initial;
    .header-contain {
      width: 100%;
      height: 60px;
      background: #000000;
      color: #ffffff;
      position: fixed;
      border-bottom: 1px solid #f2f2f2;
      .container {
        position: relative;
        z-index: 10;
        margin: 0 auto;
        max-width: 1400px;
        min-width: 960px;
        .header-box{
          position: relative;
          margin: 0 auto;
          height: 60px;
          z-index: 1290;
          background: #000000;
          color: #ffffff;
          .header-logo {
            float: left;
            margin: 0 10px;
            border: 0;
            .a {
              line-height: 60px;
              font-size: 20px;
              color: #ffffff;
              font-weight: 700;
            }
            .logo {
              height: 20px;
              margin: 10px 0;
              width: 100%;
              vertical-align: top;
            }
            .logo-content {
              display: inline-block;
              margin-left: 10px;
              color: #000;
              .name {
                line-height: 28px;
                font-size: 22px;
                font-family: "Times New Roman",Georgia,serif;
              }
              .desc {
                line-height: 14px;
                font-size: 12px;
                opacity: .6;
              }
            }
          }
          .header-nav-menu {
            display: inline-block;
          }
          .header-content {
            float: left;
            height: 28px;
            margin: 6px 20px 6px 0;
            visibility: visible;
            .header-content-head {
              li {
                float: left;
                position: relative;
                margin-right: 0;
                &:hover {
                  a {
                    color: $--color-primary;
                  }
                }
                a {
                  color: #FFFFFF;
                  display: block;
                  padding: 0 8px;
                  font-size: 14px;
                  line-height: 38px;
                  text-decoration: none;
                  zoom: 1;
                  position: relative;
                }
              }
              li:last-child {
                margin-right: 20px;
              }
            }
          }
          .header-search {
            height: 40px;
            display: inline-block;
            .header-search-input {
              display: inline-block;
              .el-input {
                margin: 5px 0;
                .el-input__wrapper {
                  border-right: none;
                  box-shadow: none;
                  background: #f7f7f7;
                }
                .el-input__inner {
                  height: 28px;
                  border-radius: 4px 0 0 4px;
                }
              }
              .header-search-button-box {
                border-radius: 0 4px 4px 0;
                display: inline-block;
                padding: 0 5px;
                .header-search-button {
                  background: #f7f7f7;
                  border-radius: 0 4px 4px 0;
                  color: #000;
                  font-size: 14px;
                  &:hover {
                    color: $--color-primary
                  }
                  span {
                    margin-left: 3px;
                    line-height: 28px;
                    display: inline-block;
                  }
                }
              }
            }
            .search-list {
              position: absolute;
              z-index: 100;
              width: calc(100% - 10px);
              background: #ffffff;
              box-shadow: 0 0 5px 0 rgb(0 0 0 / 22%), 0 5px 5px 0 rgb(0 0 0 / 30%);
              border-radius: 4px;
              left: 5px;
              .hot-search-list {
                margin: 16px 0;
                .title {
                  font-size: 14px;
                  font-weight: 500;
                  padding: 8px 16px;
                  color: #999999;
                }
                .hot-item {
                  height: 32px;
                  line-height: 32px;
                  font-size: 14px;
                  color: #000;
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  position: relative;
                  cursor: pointer;
                  &:hover {
                    color: $--color-primary;
                  }
                  .order {
                    position: absolute;
                    left: 16px;
                    text-align: left;
                    width: 12px;
                  }
                  .hot-word {
                    margin-left: 40px;
                  }
                  .tag {
                    display: inline-block;
                    margin-left: 8px;
                    background-color: #0d9bff;
                    font-size: 12px;
                    padding: 2px;
                    border-radius: 2px;
                    line-height: 12px;
                    color: #fff;
                    text-align: center;
                    position: relative;
                    top: -1px;
                  }
                  &:nth-child(2) {
                    .order {
                      color: red;
                    }
                  }
                  &:nth-child(3) {
                    .order {
                      color: pink;
                    }
                  }
                  &:nth-child(4) {
                    .order {
                      color: green;
                    }
                  }
                }
              }
            }
          }
          .header-user-center {
            float: right;
            height: 60px;
            .header-user-item {
              &:hover {
                a {
                  color: $--color-primary;
                }
              }
              &:focus-visible {
                outline: none;
              }
              float: left;
              width: auto;
              height: 60px;
              line-height: 60px;
              text-align: center;
              a {
                //max-height: 42px;
                font-size: 14px;
                margin: 0 10px;
                display: inline-block;
                color: #FFFFFF;
                cursor: pointer;
                outline: none;
                line-height: 60px;
                .icon-title {
                  display: block;
                  font-size: 14px;
                  line-height: 60px;
                  width: 100%;
                  text-align: center;
                  left: 0;
                }
                &:focus-visible {
                  outline: none;
                  a {
                    outline: none;
                  }
                }
                &:focus {
                  outline: none;
                  a {
                    outline: none;
                  }
                }
              }
              .search-btn {
                margin: 0 8px;
                cursor: pointer;
                &:hover {
                  color: $--color-primary;
                }
              }
            }
            .header-line {
              float: left;
              width: auto;
              height: 60px;
              line-height: 60px;
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .dropdown-nav-menu {
    .nav-panel {
      cursor: auto;
      -webkit-animation-duration: .3s;
      animation-duration: .3s;
      padding: 0;
      line-height: normal;
      background: #fff;
      color: #000000;
      .nav-item-content {
        padding: 20px 0 0 25px;
        .title {
          font-size: 16px;
          margin-bottom: 20px;
          cursor: pointer;
          color: #000000;
          a {
            color: #000000;
          }
        }
        dl {
          content: "";
          display: table;
          clear: both;
        }
        dd {
          padding: 0;
          margin: 0;
          border: 0;
          font-size: 0;
          width: 600px;
          float: left;
          a {
            text-decoration: none;
            zoom: 1;
            position: relative;
            float: left;
            cursor: pointer;
            border-bottom: none;
            padding: 0;
            vertical-align: top;
            font-size: 14px;
            margin: 0 22px 12px 0;
            display: inline-block;
            line-height: 20px;
            width: auto;
            overflow: hidden;
            text-overflow: inherit;
            white-space: nowrap;
            text-align: center;
            color: #6D7278;
            &:hover {
              color: $--color-primary;
            }
          }
        }
      }
    }
  }
  .color-black {
    color: #000000;
  }
  .menu-item {
    &:hover {
      color: $--color-primary!important;
      a {
        color: $--color-primary;
      }
    }
  }
  .nav-panel:focus, .nav-panel:not(.is-disabled):hover {
    background-color: #ffffff;
    color: inherit;
  }
  .dropdown {
    height: 60px;
    outline: none;
    &:focus-visible {
      outline: none;
      a {
        outline: none;
      }
    }
    &:focus {
      outline: none;
      a {
        outline: none;
      }
    }
  }
</style>
