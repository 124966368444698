import {get, post} from "@/util/requestUtils";
import {removeToken} from "@/util/tokenUtils";
import {deleteAuthorities} from "@/util/authorityUtils";
import router from "@/router";
import {deleteUser} from "@/util/userUtils";
// 账号密码登录
export function passwordLogin(data, callback) {
  return post("/login", data, callback);
}
// 获取验证码
export function getMsgAuthCode(mobile, callback) {
  return get("/auth/public-api/auth-code", { mobile: mobile }, callback);
}
// 获取注册验证码
export function getRegAuthCode(mobile, callback) {
  return post("/member/public-api/send/auth-code", { mobile: mobile }, callback);
}
// 验证码登录
export function authCodeLogin(data, callback) {
  return post("/login/auth-code", data, callback);
}
// 获取登录二维码
export function getLoginQrCode(callback) {
  return get("/qr-code", undefined, callback);
}
// 企业微信登录
export function workWeChatLogin(data, callback) {
  return post("/login/work-we-chat", data, callback);
}
// 钉钉登录
export function dingTalkLogin(data, callback) {
  return post("/login/ding-talk", data, callback);
}
export function loginOut() {
  // 删除缓存
  deleteAuthorities();
  // 删除token
  removeToken()
  // 删除用户
  deleteUser()
  window.location.reload()
}
// 刷新token
export function refreshAccessToken(data, callback) {
  return post("/login/refresh", data, callback).catch(() => {
    loginOut()
  });
}
// 获取当前登录用户
export function getCurrentUser(callback) {
  return get("/current-user", {}, callback).then(() => {
  }).catch(() => {
    loginOut()
    router.push("/login")
  })
}
// 获取企业微信配置
export function getWorkWeChatConfig(callback) {
  return get("/user-center/public-api/work-we-chat/config", {}, callback)
}
// 获取企业微信配置
export function getDingTalkConfig(callback) {
  return get("/user-center/public-api/ding-talk/config", {}, callback)
}
