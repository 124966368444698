import { createStore } from "vuex";
import Cookies from "js-cookie"
import {routes} from "../router";

export default createStore({
  state: {
    // 用户拥有的所有权限
    authorities: {},
    routes: [],
    addRoutes: [],
    asideStatus: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : false,
    // el-main的高度
    mainHeight: 0
  },
  // set方法
  mutations: {
    setAuthorities(state, authorities){
      state.authorities = authorities
    },
    setRoutes(state, routeList){
      state.addRoutes = routeList
      state.routes = routes.concat(routeList)
    },
    toggleAsideStatus: state => {
      state.asideStatus = !state.asideStatus
      if (state.asideStatus) {
        Cookies.set("sidebarStatus", 1)
      } else {
        Cookies.set("sidebarStatus", 0)
      }
      console.log(state.asideStatus)
    },
    setMainHeight(state, height) {
      state.mainHeight = height
    }
  },
  // get方法
  getters: {
    getAuthorities: state => state.authorities,
    getRoutes: state => state.routes,
    getAddRoutes: state => state.addRoutes,
    getAsideStatus: state => state.asideStatus
  },
  actions: {
    toggleAsideStatus({ commit }) {
      commit("toggleAsideStatus")
    },
    generateRoutes({ commit }, accessedRoutes) {
      return new Promise(resolve => {
        commit("setRoutes", accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  },
  modules: {}
});

