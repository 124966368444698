<template>
  <el-container>
    <el-header class="el-header" height="60px">
      <custom-header/>
    </el-header>
    <el-main class="main" :style="'min-height:' + clientHeight + 'px'">
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component"/>
        </transition>
      </router-view>
      <Login :show="showLoginFlag" @callback="loginCallback" @success="successCallback" :show-close="showLoginClose"/>
      <el-backtop :right="20" :bottom="20"></el-backtop>
    </el-main>
    <custom-footer/>
  </el-container>
</template>

<script>
import CustomHeader from "./Header.vue";
import CustomFooter from "./Footer.vue";
import store from "../store";
import {ref, provide} from "vue";
import {getMember} from "@/api/member";
import router from "@/router";

export default {
  name: "LayoutIndex",
  components: {
    CustomHeader,
    CustomFooter
  },
  computed: {
    opened() {
      return !store.getters.getAsideStatus
    }
  },
  setup() {
    let clientHeight = document.documentElement.clientHeight - 60;
    if (clientHeight < 500) {
      clientHeight = 500;
    }
    store.commit("setMainHeight", clientHeight)
    const showLoginFlag = ref(false)
    const showLoginClose = ref(true)
    provide("showLogin", showLoginFlag)
    provide("showLoginClose", showLoginClose)
    const loginCallback = function() {
      showLoginFlag.value = false
    }
    const successCallback = async function() {
      await getMember()
      router.go(0);
    }
    return {
      clientHeight,
      showLoginFlag,
      showLoginClose,
      loginCallback,
      successCallback
    }
  }
};
</script>

<style scoped>
.el-container{
  height:100%;
  position: relative;
}
.el-header, .el-footer, .el-main {
  padding: 0!important;
}
.main {
  min-height: 942px;
  position: relative;
  /*max-width: 1400px;*/
  /*min-width: 960px;*/
  margin: 0 auto;
  width: 100%;
}
.main::-webkit-scrollbar{
  width: 0;
  height: 0;
}
/* 当页面宽度大于1200px*/
@media screen and (min-width:1900px) {
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1400px;
    min-width: 1400px;
    width: 1400px;
  }
}
/* 当页面宽度大于1200px*/
@media screen and (min-width:1500px) and (max-width:1900px) {
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1400px;
    min-width: 1400px;
    width: 1400px;
  }
}
/* 当页面宽度大于1200px*/
@media screen and (min-width:1300px) and (max-width:1500px) {
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1200px;
    min-width: 1200px;
    width: 1200px;
  }
}
/* 当页面宽度大于960px小于1200px */
@media screen and (min-width:1100px) and (max-width:1300px){
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1080px;
    min-width: 1080px;
    width: 1080px;
  }
}
/* 当页面宽度大于600px小于960px */
@media screen and (min-width:980px) and (max-width:1100px){
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1080px;
    min-width: 1080px;
    width: 1080px;
  }
}
/* 当页面宽度大于600px小于960px */
@media screen and (max-width:960px){
  .main,
  ::v-deep .position-fixed .nav-menu-main,
  ::v-deep .header-container .header-contain .container,
  /*::v-deep .nav-menu .nav-menu-main,*/
  ::v-deep .footer .wrapper .content,
  ::v-deep .footer .wrapper .bottom .copyright-box {
    max-width: 1080px;
    min-width: 1080px;
    width: 1080px;
  }
}
</style>
