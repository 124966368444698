import {get, post, put, del } from "@/util/requestUtils"

export function getResource(id, success) {
  return get("/resource/public-api/resource", { id: id }, success)
}

export function getResourceList(params, success) {
  return get("/resource/auth-api/resource/list", params, success)
}

export function getResourceRecommendList(params, success) {
  return get("/resource/public-api/resource/recommend-list", params, success)
}

export function saveResource(data, success) {
  return post("/resource/auth-api/resource", data, success)
}

export function updateResource(data, success) {
  return put("/resource/auth-api/resource", data, success)
}

export function deleteResource(data, success) {
  return del("/resource/auth-api/resource", data, success)
}

export function download(params, success) {
  return get("/resource/auth-api/resource/download", params, success)
}

export function getResourceType(params, success) {
  return get("/resource/public-api/resource/type/list", params, success)
}

export function getMemberResourceList(params, success) {
  return get("/resource/auth-api/member/resource/list", params, success)
}

export function getMemberDownloadResourceList(params, success) {
  return get("/resource/auth-api/member/download/resource/list", params, success)
}

export function getListByIds(params, success) {
  return get("/resource/public-api/resource/list/by-ids", params, success)
}

export function getResourceLastSearchRecord(success) {
  return get("/resource/auth-api/member/last-search-record", {}, success)
}

export function getResourceProductList(param, success) {
  return get("/resource/public-api/resource/product/list", param, success)
}

export function getResourceTagList(param, success) {
  return get("/resource/public-api/resource/tag/list", param, success)
}
