<template>
  <div class="module-mod">
    <div class="common-container module-mod-bottom">
      <div class="module-header">
        <div class="head">
          <h2 class="head-title">
            <div class="title">
              <span v-if="icon" style="margin-right: 5px;">
                <svg-icon :name="icon"/>
              </span>
              <router-link :to="{path: type}">{{hotItem.name}}</router-link>
            </div>
          </h2>
        </div>
        <div class="module-sub-title" v-if="hotItem.moduleLinkList && hotItem.moduleLinkList.length">
          <div class="module-link-wrap" v-for="item in hotItem.moduleLinkList" :key="item.id">
            <a :href="item.href" target="_self" :title="item.name">{{item.name}}</a>
          </div>
        </div>
        <a class="module-right-content" title="换一换" @click="change">
          <span class="el-icon-refresh"></span> 换一换
        </a>
      </div>
      <div class="hot-g-row" v-loading="dataLoading">
        <el-empty v-if="!(contentList && contentList.length)" style="background: #ffffff; margin: 10px 10px 0;border-radius: 4px;"/>
        <rectangle v-else v-for="(item, index) in contentList" :link="type ? '/' + type + '/detail' : undefined" :item="item" :key="item.id" :width="width" :row-first-item="index !== 0 && index % rowItemNum === 0"></rectangle>
      </div>
    </div>
  </div>
</template>

<script>
  import rectangle from "./component/rectangle";
  import {nextTick, ref, watch} from "vue";
  import SvgIcon from "@/components/SvgIcon";
  export default {
    name: "HotIndex",
    components: {
      SvgIcon,
      rectangle
    },
    props: {
      hotItem: {
        type: Object,
        default: () => {
          return {
            moduleLinkList: [],
            contentList: []
          }
        }
      },
      type: {
        type: String,
        default: "learn"
      },
      icon: {
        type: String,
        default: null
      },
    },
    setup(props) {
      const width = ref("")
      const calcWidth = () => {
        let clientWidth = document.body.clientWidth;
        if (clientWidth > 1400) {
          clientWidth = 1400;
        }
        if (clientWidth < 1300) {
          clientWidth = 1300;
        }
        const mWidth = 180
        for (let i = 2;; i++) {
          const totalWidth = 100 + mWidth * i
          if (clientWidth < totalWidth) {
            return 1;
          } else if (totalWidth <= clientWidth && clientWidth < totalWidth + mWidth) {
            return i - 2 || 1;
          }
        }
      }
      // 显示多少行
      const showRowNum = 2
      // 每行多少数量
      const rowItemNum = ref(1)
      // 换一换
      const contentList = ref([])
      let pageNum = 0;
      const dataLoading = ref(true)
      const change = () => {
        dataLoading.value = true
        if (props.hotItem && props.hotItem.contentList && props.hotItem.contentList.length) {
          const pageSize = rowItemNum.value * showRowNum;
          const start = pageNum * pageSize;
          let end = (pageNum + 1) * pageSize;
          if (end >= props.hotItem.contentList.length) {
            end = props.hotItem.contentList.length;
            pageNum = 0
          } else {
            pageNum++;
          }
          contentList.value = props.hotItem.contentList.slice(start, end);
        }
        dataLoading.value = false
      }
      nextTick(function() {
        const x = 10 * 2
        width.value = "calc(" + 100 / calcWidth() + "% - " + x + "px)"
        rowItemNum.value = calcWidth()
        window.addEventListener("resize", () => {
          const x = 10 * calcWidth() * 2 / calcWidth()
          width.value = "calc(" + 100 / calcWidth() + "% - " + x + "px)"
          rowItemNum.value = calcWidth()
        });
      });
      watch(() => rowItemNum.value, () => {
        change()
      });
      watch(() => props.hotItem.contentList, () => {
        change()
      });
      return {
        width,
        rowItemNum,
        change,
        contentList,
        dataLoading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .module-mod {
    color: #000;
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
    .common-container {
      position: relative;
      text-align: left;
      margin: 0 auto;
      overflow: hidden;
      .module-mod-header, .module-header {
        display: flex;
        .head {
          line-height: 40px;
          height: 40px;
          display: inline-block;
          &:before {
            content: " ";
            display: table;
          }
          .head-title {
            margin-top: 20px;
            margin-left: 10px;
            font-size: 20px;
            float: left;
            margin-right: 12px;
            transform: translateY(-50%);
            color: #222;
            a {
              color: #222;
            }
          }
          &:after {
            clear: both;
            content: " ";
            display: table;
          }
        }
      }
    }
    .module-sub-title {
      height: 40px;
      // width: 1200px;
      line-height: 40px;
      overflow: hidden;
      white-space: pre-wrap;
      display: inline-block;
      .module-link-wrap {
        display: inline-block;
        text-align: center;
      }
      .module-link-wrap {
        display: inline-block;
        text-align: center;
        color: #fff;
        a, span {
          color: #666;
          margin-right: 2px;
          padding: 0 10px;
          font-size: 14px;
          cursor: text;
        }
      }
    }
    .module-right-content {
      position: absolute;
      right: 10px;
      font-size: 14px;
      color: #666;
      line-height: 40px;
      &:hover {
        color: $--color-primary;
      }
    }
    .hot-g-row {
      width: 100%;
      z-index: 1;
      overflow: auto;
      &::-webkit-scrollbar { width: 0 !important }
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      min-height: 266px;
    }
  }
</style>
