<template>
  <div class="news-wrapper">
    <div class="module-header">
      <div class="head">
        <h2 class="head-title">
          <div class="title">
            <span style="margin-right: 5px;">
              <svg-icon name="news"/>
            </span>
            <router-link :to="{path: '/news'}">资讯</router-link>
          </div>
        </h2>
      </div>
    </div>
    <div v-if="!(recommendList && recommendList.length && newsList && newsList.length)">
      <el-empty/>
    </div>
    <el-row v-else :gutter="10">
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="news-left" v-loading="topDataLoading">
          <a target="_blank" class="news-left-item" :class="{'nodata': !item.id}" @click="gotoDetail(item.id)" :title="item.title" v-for="item in topList" :key="item.image">
            <div class="title-box">
              <div class="title">
                {{item.title}}
              </div>
            </div>
            <img :src="item.image" v-if="item.image" class="left-item-img">
          </a>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="news-middle" v-loading="recommendDataLoading">
          <div class="News-content">
            <div class="News-bigNews">
              <router-link class="News-bigNewsOne" v-for="(item, index) in recommendList" :key="index + ''" :to="{path: '/news/detail', query: {id: item.id}}" target="_blank">
                <div class="News-firstFloor">
                  <div class="News-newsIcon1" :class="{'News-newsIconBg1': index < 2, 'News-newsIcon2': index >= 2}"></div>
                  <div class="News-newsTitle" :title="item.title">{{item.title}}</div>
                </div>
                <div class="News-secondFloor" :title="item.description">{{item.description}}</div>
              </router-link>
            </div>
            <div class="News-normalNews">
              <router-link class="News-normalNewsOneOut" v-for="(item, index) in recommendBottomList" :key="index + ''" :to="{path: '/news/detail', query: {id: item.id}}" target="_blank">
                <div class="News-normalNewsOne">
                  <div class="News-normalNewsTitle"></div>
                  <div class="News-normalNewsWord" :title="item.title">{{item.title}}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="news-right" v-loading="articleRecommendDataLoading">
          <div class="article-recommend-box">
            <router-link class="article-recommend" :to="{path: '/news/detail', query: {id: item.id}}" target="_blank" v-for="(item, index) in newsList" :key="item.id">
              <div class="article-box">
                <div class="article-image">
                  <div class="article-image-box ">
                    <img :src="item.image" :alt="item.title" class="article-image-box-content is-normal">
                  </div>
                  <span class="article-index">{{index + 1}}</span>
                  <span class="article-time"></span>
                  <span class="article-play-icon"></span>
                </div>
                <div class="article-content">
                  <div class="article-title" :title="item.title">
                    {{item.title}}
                  </div>
                  <div class="article-sub">
                    <div class="article-sub-name">浏览量 {{item.watchNum || 0}}</div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {ref} from "vue";
  import {findRecommendList, findTopList, findList} from "@/api/content/news";
  import router from "@/router";
  import SvgIcon from "@/components/SvgIcon";

  export default {
    name: "ContentIndex",
    components: {SvgIcon},
    setup() {
      const topList = ref([{image: ""}, {image: ""}, {image: ""}])
      const topDataLoading = ref(true)
      findTopList({size: 4, current: 1}, res => {
        topDataLoading.value = false;
        if (!res) {return;}
        for (let i = 0; i < res.list.length; i++) {
          topList.value[i] = res.list[i];
        }
      }).catch(() => {
        topDataLoading.value = false;
      })
      const recommendList = ref([])
      const recommendBottomList = ref([])
      const recommendDataLoading = ref(true)
      findRecommendList({size: 8, current: 1}, res => {
        recommendDataLoading.value = false;
        if (!res) {return;}
        let split = 3;
        if (res.list.length < split) {
          split = res.list.length
        }
        recommendList.value = res.list.slice(0, split);
        if (res.list.length > 3) {
          split = res.list.length
          recommendBottomList.value = res.list.slice(3, split);
        }
      }).catch(() => {
        recommendDataLoading.value = false;
      })
      const articleRecommendDataLoading = ref(true)
      const newsList = ref([])
      findList({size: 5, current: 1}, res => {
        articleRecommendDataLoading.value = false;
        if (!res) {return;}
        newsList.value = res.list
      }).catch(() => {
        articleRecommendDataLoading.value = false;
      })
      const gotoDetail = (id) => {
        if (!id) {
          return;
        }
        router.push({path: "/news/detail", query: {id: id}})
      }
      return {
        topList,
        topDataLoading,
        recommendList,
        recommendBottomList,
        recommendDataLoading,
        articleRecommendDataLoading,
        newsList,
        gotoDetail
      }
    }
  }
</script>

<style scoped lang="scss">
  .news-wrapper {
    margin-left: auto;
    margin-right: auto;
    height: inherit;
    margin-bottom: 20px;
    &:before {
      content: " ";
      display: table;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
    .module-header {
      display: flex;
      margin-bottom: 10px;
      .head {
        line-height: 40px;
        height: 40px;
        display: inline-block;
        &:before {
          content: " ";
          display: table;
        }
        .head-title {
          margin-top: 20px;
          margin-left: 10px;
          font-size: 20px;
          float: left;
          margin-right: 12px;
          transform: translateY(-50%);
          color: #222;
          a {
            color: #222;
          }
        }
        &:after {
          clear: both;
          content: " ";
          display: table;
        }
      }
    }
    .news-left {
      padding: 0 10px;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      max-height: 340px;
      min-height: 316px;
      .news-left-item {
        overflow: hidden;
        border-radius: 4px;
        background-color: #fff;
        height: calc(50% - 10px);
        width: calc(50% - 10px);
        margin: 0 10px 10px 0;
        display: inline-block;
        position: relative;
        cursor: pointer;
        .title-box {
          background-image: linear-gradient(
                  -180deg
              , rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          bottom: 0;
          line-height: 50px;
          .title {
            color: #FFF;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &:hover {
          box-shadow: 0 1px 1px 0 rgb(158 172 182 / 60%);
        }
      }
      .news-left-item:nth-child(2) {
        margin: 0 0 10px 10px;
      }
      .news-left-item:nth-child(3) {
        margin: 0 10px 0 0;
      }
      .news-left-item:nth-child(4) {
        margin: 0 0 0 10px;
      }
      .news-left-item.nodata {
        cursor: default;
        &:hover {
          box-shadow: none;
        }
      }
      .left-item-img {
        width: 100%;
        height: 100%;
      }
    }
    .news-middle {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      border-radius: 6px;
      height: 100%;
      max-height: 340px;
      .News-content {
        width: 100%;
        height: 100%;
        position: relative;
        background: #fff;
        border-radius: 6px;
        padding: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .News-bigNews {
          width: 100%;
          height: 50%;
          .News-bigNewsOne {
            width: 100%;
            height: 33.3333%;
            display: block;
            box-sizing: border-box;
            cursor: pointer;
            .News-firstFloor {
              width: 100%;
              height: 20px;
              text-align: left;
              .News-newsIconBg1 {
                background: url(../../assets/hot.png);
                background-size: cover;
                width: 36px;
                height: 16px;
                float: left;
              }
              .News-newsIcon2 {
                background: url(../../assets/news.png);
                background-size: cover;
                width: 36px;
                height: 16px;
                float: left;
              }
              .News-newsTitle {
                font-size: 14px;
                color: #333;
                float: left;
                margin-top: -3px;
                margin-left: 8px;
                font-weight: 700;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 44px);
              }
            }
            .News-secondFloor {
              font-size: 12px;
              color: #999;
              width: 100%;
              height: 20px;
              text-align: left;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:hover {
              .News-newsTitle {
                color: $--color-primary;
              }
            }
          }
        }
        .News-normalNews {
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          height: 50%;
          .News-normalNewsOneOut {
            width: 100%;
            height: 20%;
            position: relative;
            display: block;
            min-height: 28px;
            .News-normalNewsOne {
              width: 100%;
              height: 20px;
              color: #666;
              cursor: pointer;
              position: absolute;
              top: 50%;
              margin-top: -10px;
              .News-normalNewsTitle {
                float: left;
                margin-right: 5px;
              }
              .News-normalNewsWord {
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
              }
              &:hover {
                .News-normalNewsWord {
                  color: $--color-primary;
                }
              }
            }
          }
        }
      }
    }
    .news-right {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      height: 100% ;
      padding-left: 10px;
      max-height: 340px;
      .article-recommend-box {
        background-color: #fff;
        box-sizing: border-box;
        height: 100%;
        padding: 0;
        border-radius: 4px;
        .article-recommend {
          overflow: hidden;
          display: block;
          height: calc(20% - 8px);
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
          }
          &:hover {
            .article-title {
              color: $--color-primary;
            }
          }
        }
        .article-recommend:first-child .article-index {
          background-color: #ff5249;
        }
        .article-recommend:nth-child(2) .article-index {
          background-color: #ff7f29;
        }
        .article-recommend:nth-child(3) .article-index {
          background-color: #fcc54e;
        }
        .article-box>div {
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
        }
        .article-image {
          overflow: hidden;
          position: relative;
          width: 110px;
          height: 56px;
          border-radius: 4px;
          color: #fff;
          .article-image-box {
            position: relative;
            overflow: hidden;
            height: 100%;
            background: #f5f5f5;
          }
          .article-image-box-content.is-normal {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .article-index {
            top: 0;
            left: 0;
            width: 20px;
            height: 14px;
            line-height: 14px;
            border-radius: 4px 0;
            background-color: #b9b9b9;
          }
          .article-index, .article-time {
            position: absolute;
            text-align: center;
          }
        }
        .article-content {
          padding: 0 0 0 10px;
          width: calc(100% - 120px);
          .article-title {
            margin: 0 0 4px 0;
            max-height: 36px;
            height: 36px;
            line-height: 18px;
            font-weight: 600;
            color: #333;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .article-sub {
            height: 24px;
            line-height: 24px;
            font-size: 0;
            .article-image-box {
              height: 20px;
              margin-right: 6px;
              width: 20px;
              img {
                width: 20px;
                height: 20px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
              }
            }
            .article-sub-name {
              width: calc(100% - 26px);
              color: #999;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            div {
              display: inline-block;
              vertical-align: middle;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
</style>
