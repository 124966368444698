<template>
  <div>
    <div class="index-menu">
      <div class="banner-menu">
        <div class="submenu" @mouseenter="showSubmenu = true" @mouseleave="showSubmenu = false" :class="{'hide': !showSubmenu}">
          <div class="inner-box">
            <div v-for="item in submenu.children" :key="item.label" class="submenu-module">
              <h2 class="type">
                <router-link target="_blank" :to="{path: '/' + submenu.type + (submenu.type === 'ask' ? '' : '/list'), query: {cid: item.value}}">{{item.label}}</router-link>
              </h2>
              <div class="tag clearfix">
              </div>
              <div class="lore">
                <p class="lore-list clearfix">
                  <router-link target="_blank" :to="{path: '/' + submenu.type + (submenu.type === 'ask' || submenu.type === 'article' ? '' : '/list'), query: {cid: subItem.value}}" v-for="subItem in item.children" :key="subItem.label">{{subItem.label}}</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 目录 -->
        <div class="menu-content">
          <!--          <el-empty v-if="!(menuList && menuList.length)"/>-->
          <div class="item" v-for="item in menuList" :key="item.title" @mouseenter="categoryHover(item.type)" @mouseleave="showSubmenu = false" :class="{'hover-menu': showSubmenu && showSubmenuType === item.type}">
            <span class="title">{{item.title}}</span>
            <span class="sub-title">{{item.subTitle}}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <!-- 轮播图 -->
        <Banner :carousel="carousel" class="banner-box"/>
        <!-- 个人信息 -->
        <div class="member">
          <div class="member-bd" data-spm-ab="1">
            <div class="avatar-wrapper" v-if="member && member.id">
              <a class="member-home" @click="goto('/member/personal')" target="_self">
                <img class="member-avatar" :src="member.avatar" v-if="member && member.avatar">
              </a>
            </div>
            <span class="member-nick-info" :class="{'no-login': !(member && member.id)}">
              <div @click="goto('/member/personal')" class="member-nick" :title="member.name">{{member.name}}</div>
              <p v-if="member && member.id" style="color: #9199A1; font-size: 12px;">等级：{{member && member.level ? member.level.name : "--"}}</p>
            </span>
            <div class="signature" v-if="checkInLog && checkInLog.updateTime">
              <span>最近签到：{{checkInLog.updateTime}}</span>
              <span>
                <a data-spm="d2" @click="memberCheckIn" class="h" :class="{'hed': checkInLog && checkInLog.isCheckInToday}">
                  <i class="el-icon-coin" v-if="!(checkInLog && checkInLog.isCheckInToday)"></i> {{checkInLog && checkInLog.isCheckInToday ? '已签到' : '签到'}}
                </a>
              </span>
            </div>
            <p class="member-point" v-if="member && member.id" style="display: none;">
              <a data-spm="d2" @click="memberCheckIn" class="h" :class="{'hed': checkInLog && checkInLog.isCheckInToday}">
                <i class="el-icon-coin" v-if="!(checkInLog && checkInLog.isCheckInToday)"></i> {{checkInLog && checkInLog.isCheckInToday ? '已连续签到' + checkInLog.continuousNum + '天' : '签到领积分'}}
              </a>
              <a class="h club" @click="goto('/member/personal')"><i class="el-icon-user"></i> 个人信息</a>
            </p>
          </div>
          <div class="member-ft" v-if="member && member.id">
            <div class="member-login J_MemberLogin" style="display: block;">
              <div class="member-column-4">
                <a @click="goto('/member/ask')">
                  <strong>{{memberCount.question || 0}}</strong>我的问题
                </a>
                <a @click="goto('/member/article')">
                  <strong>{{memberCount.article || 0}}</strong>我的文章
                </a>
                <a @click="goto('/member/circle')">
                  <strong>{{memberCount.circle || 0}}</strong>我的社区
                </a>
                <a @click="goto('/member/point')">
                  <strong>{{memberCount.point || 0}}</strong>我的积分
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- 公告 -->
      <one-announcement/>
      <!-- 课程 -->
      <hot :hot-item="hotItem" type="learn" icon="lesson"/>
      <!-- 直播 -->
      <hot :hot-item="liveHotItem" v-if="liveHotItem.contentList && liveHotItem.contentList.length" type="live" icon="live"/>
      <!-- 新闻资讯 -->
      <custom-content/>
      <!-- 文章 -->
      <custom-article/>
      <!-- 问答 -->
      <question/>
      <!-- 社区 -->
      <custom-circle/>
      <!-- 知识库 -->
      <resource/>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue"
import {getRecommendLesson, getCategory} from "../../api/index"
import Banner from "../module/banner"
import hot from "../module/hot";
import CustomContent from "./content";
import Question from "./question";
import CustomCircle from "./circle";
import {getUser} from "@/util/userUtils";
import {getToken} from "@/util/tokenUtils";
import {getCarousel} from "@/api/setting/carousel";
import {getCheckIn} from "@/api/member";
import router from "@/router";
import {success} from "@/util/tipsUtils";
import {liveList} from "@/api/live";
import {checkIn} from "@/api/member"
import {countMemberPoint} from "@/api/point";
import {countMemberArticle} from "@/api/content/article";
import {countMemberQuestion} from "@/api/ask";
import {countMemberCircle} from "@/api/circle";
import Resource from "@/views/index/resource";
import CustomArticle from "@/views/index/article";
import OneAnnouncement from "@/views/announcement/components/oneannouncement";
export default {
  name: "HomeIndex",
  components: {
    OneAnnouncement,
    CustomArticle,
    Resource,
    CustomCircle,
    Question,
    CustomContent,
    hot,
    Banner
  },
  setup() {
    const carousel = ref({})
    getCarousel({}, (res) => {
      const carouselJsonStr = res.carouselJson;
      if (carouselJsonStr) {
        carousel.value = JSON.parse(carouselJsonStr);
      }
    })
    // 课程
    const hotItem = ref({
      id: 0,
      name: "课程",
      contentList: [],
      moduleLinkList: [{id: 0, name: "", href: "javascript:void(0)"}]
    })
    const hotDataLoading = ref(true)
    const loadRecommendLesson = function() {
      hotDataLoading.value = true
      getRecommendLesson({}, res => {
        hotItem.value.contentList = res
        hotDataLoading.value = false
      })
    }
    loadRecommendLesson()
    // 直播
    const liveHotItem = ref({
      id: 0,
      name: "直播",
      contentList: [],
      moduleLinkList: [{id: 0, name: "", href: "javascript:void(0)"}]
    })
    const liveHotDataLoading = ref(true)
    liveList({}, res => {
      liveHotItem.value.contentList = res.list
      liveHotDataLoading.value = false
    })
    // 目录
    const showSubmenu = ref(false);
    const categoryList = ref([])
    const categoryIndexMap = ref({})
    const menuList = ref([])
    getCategory( (res) => {
      categoryList.value.push(res)
      categoryIndexMap.value[res.type] = res.value
      const children = res.children;
      let label = "";
      for (let i = 0; i < children.length; i++) {
        label += children[i].label
        if (i === 2) {
          break;
        }
        if (i !== children.length - 1) {
          label += "/";
        }
      }
      menuList.value.push({type: res.type, title: res.label + " ", subTitle: label})
    })
    const submenu = ref({})
    const showSubmenuType = ref("")
    const categoryHover = (type) => {
      submenu.value = categoryList.value[categoryIndexMap.value[type]]
      showSubmenu.value = true
      showSubmenuType.value = type;
    }
    // 会员信息
    let member;
    const loginMember = getUser();
    if (loginMember && getToken()) {
      member = ref(loginMember);
    } else {
      member = ref({
        name: "用户未登录，点击登录",
        avatar: ""
      })
    }
    const showLoginFlag = inject("showLogin")
    const goto = (url) => {
      if (!member.value.id) {
        showLoginFlag.value = true;
        return;
      }
      router.push({path: url})
    }
    // 获取签到记录
    const checkInLog = ref(null)
    const loadCheckIn = () => {
      getCheckIn((res) => {
        checkInLog.value = res
      })
    }
    loadCheckIn()
    // 签到
    const memberCheckIn = () => {
      if (!member.value.id) {
        showLoginFlag.value = true;
        return;
      }
      if (checkInLog.value && checkInLog.value.isCheckInToday) {
        return;
      }
      checkIn(() => {
        success("签到成功")
        loadCheckIn()
      })
    }
    // 获取会员积分
    const memberCount = ref({
      point: 0,
      article: 0,
      question: 0,
      circle: 0
    })
    countMemberPoint((res) => {
      memberCount.value.point = res
    })
    countMemberArticle((res) => {
      memberCount.value.article = res
    })
    countMemberQuestion({}, (res) => {
      memberCount.value.question = res
    })
    countMemberCircle((res) => {
      memberCount.value.circle = res
    })
    return {
      hotItem,
      hotDataLoading,
      liveHotItem,
      liveHotDataLoading,
      showSubmenu,
      categoryList,
      menuList,
      submenu,
      categoryHover,
      member,
      carousel,
      showSubmenuType,
      goto,
      memberCheckIn,
      memberCount,
      checkInLog
    }
  }
}
</script>
<style scoped lang="scss">
.hide {
  display: none;
}
.position-fixed {
  position: fixed;
  z-index: 2001;
  top: 40px;
  left: 0;
}
//.nav-menu {
//  width: 100%;
//  height: 40px;
//  background: #ffffff;
//  border-bottom: 1px solid #f2f2f2;
//  padding: 0;
//  line-height: 40px;
  //.nav-menu-main {
  //  max-width: 1400px;
  //  min-width: 960px;
  //  margin: 0 auto;
  //  padding: 0 10px;
  //  color: #000000;
  //  .nav-menu-main-box {
  //    margin: 0;
  //    .nav-item {
  //      display: inline;
  //      margin-right: 20px;
  //      a {
  //        float: left;
  //        display: inline;
  //        padding: 0 10px;
  //        color: inherit;
  //        cursor: pointer;
  //        &:first-child {
  //          padding-left: 0;
  //        }
  //        &:hover {
  //          color: $--color-primary;
  //        }
  //      }
  //    }
  //  }
  //}
//}
.index-menu {
  margin: 20px auto 20px;
  border: 1px solid #f2f2f2;
  width: calc(100% - 20px);
  background: #ffffff;
  border-radius: 4px;
  //box-shadow: 0 1px 1px 0 rgb(95 101 105 / 15%);
  //height: 346px;
  //padding: 0 0 6px;
}
.nav {
  height: 30px;
  line-height: 30px;
  position: relative;
  color: #000;
  margin: 0 auto;
  padding: 6px 10px 0 0;
  h2 {
    float: left;
    width: 290px;
    text-align: left;
    font-size: 16px;
  }
  .nav-hd {
    position: relative;
    z-index: 5;
    float: left;
    display: inline;
    font-size: 16px;
    font-weight: 700;
    li {
      margin-left: 7px;
      position: relative;
      float: left;
      a {
        float: left;
        display: inline;
        padding: 0 5px;
        color: inherit;
        cursor: pointer;
        &:hover {
          color: $--color-primary;
        }
      }
    }
    li.pipe:before, li.pipe:before {
      content: '';
    }
  }
  .nav-bd {
    color: #333;
    float: left;
    *display: inline;
    font-size: 14px;
    li {
      float: left;
      position: relative;
      margin-left: 7px;
      a {
        float: left;
        *display: inline;
        padding: 0 5px;
        color: inherit;
        cursor: pointer;
        &:hover {
          color: $--color-primary;
        }
      }
    }
    li.pipe:before, li.pipe:before {
      content: '';
    }
  }
}
.clearfix:after, .clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  content: "";
  clear: both;
  display: table;
  overflow: hidden;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.banner-menu {
  padding: 16px 0;
  display: flex;
  position: relative;
  min-height: 300px;
  .banner-box {
    width: calc(100% - 600px);
    min-width: calc(100% - 600px);
    border-radius: 4px;
    .banner {
      border-radius: 4px;
    }
  }
  .submenu {
    position: absolute;
    left: 300px;
    width: calc(100% - 600px);
    height: calc(100% - 30px);
    background: #FFFFFF;
    z-index: 3;
    box-sizing: border-box;
    .inner-box {
      height: 100%;
      padding: 0 36px;
      box-sizing: border-box;
      overflow: auto;
      .submenu-module {
        padding-top: 20px;
      }
      .type {
        margin-bottom: 10px;
        font-size: 16px;
        color: #1C1F21;
        line-height: 22px;
        font-weight: bold;
        a {
          color: #000000;
          &:hover {
            color: $--color-primary;
          }
        }
      }
      .tag {
        margin-bottom: 12px;
      }
      .lore {
        font-size: 12px;
        line-height: 24px;
        color: #6D7278;
        margin-bottom: 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        .title {
          color: #1C1F21;
          font-weight: bold;
        }
        .lore-list {
          width: 0;
          flex: 1;
          a {
            float: left;
            color: #6D7278;
            margin-right: 24px;
            &:hover {
              color: $--color-primary;
            }
          }
        }
      }
    }
  }
}
.menu-content {
  position: relative;
  float: left;
  z-index: 2;
  box-sizing: border-box;
  background: #fff;
  font-weight: 400;
  width: 300px;
  overflow: auto;
  height: 300px;
  .item {
    line-height: 50px;
    cursor: pointer;
    position: relative;
    background: #fff;
    padding: 0 30px 0 20px;
    height: 50px;
    transition: all .1s;
    font-size: 14px;
    background-size: cover;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
    overflow: hidden;
    .title {
      color: #000;
      font-weight: 500;
    }
    i {
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 34%;
      color: #cccccc;
    }
    &:hover {
      color: $--color-primary;
      background: #d3e6ff;
      i {
        color: $--color-primary;
      }
    }
    &:before{
      display: inline-block;
      content: "";
      height: 100%;
      vertical-align: middle;
    }
  }
  .hover-menu {
    color: $--color-primary;
    background: #d3e6ff;
    i {
      color: $--color-primary;
    }
  }
}
.member {
  width: 300px;
  padding: 0;
  text-align: center;
  background-repeat: no-repeat;
  .member-bd {
    position: relative;
    height: auto;
    margin-bottom: 10px;
    .avatar-wrapper {
      display: inline-block;
      margin: 0 auto;
      width: 70px;
      height: 56px;
    }
    .member-home {
      display: inline-block;
      border: 1px solid #f5f5f5;
      background: #f5f5f5;
      -webkit-border-radius: 50%;
      -webkit-background-clip: padding-box;
      -moz-border-radius: 50%;
      -moz-background-clip: padding;
      border-radius: 50%;
      background-clip: padding-box;
      width: 50px;
      height: 50px;
      line-height: 50px;
      overflow: hidden;
    }
    .member-avatar {
      width: 50px;
      height: 50px;
    }
  }
  .member-nick-info {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    vertical-align: top;
    width: 204px;
    margin: 4px 0 4px 4px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    .member-nick {
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }
  .no-login {
    margin-top: 45%;
    text-align: center;
  }
  .signature {
    margin: 5px 0 0;
    color: #9199A1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 20px;
    text-align: left;
    font-size: 12px;
    line-height: 24px;
  }
  .member-point {
    margin-top: 16px;
    line-height: 30px;
    .h {
      display: inline-block;
      border-radius: 4px;
      background-clip: padding-box;
      background: #ffffff;
      padding: 0 20px;
      position: relative;
      border: 1px solid #EEEEEE;
      color: #9199A1;
      &:hover {
        color: $--color-primary;
        border-color: $--color-primary;
      }
      margin: 0 5px;
    }
    .hed {
      color: #999999;
      border-color: #999999;
      cursor: default;
      &:hover {
        background: none;
        border-color: #999999;
      }
    }
    .club {
    }
  }
  //.no-login {
  //  margin-left: 10px;
  //  background: #f5f5f5;
  //  border-radius: 4px;
  //  .el-empty {
  //    background: #ffffff;
  //    border-radius: 4px;
  //    ::v-deep .el-empty__image {
  //      width: 102px;
  //    }
  //  }
  //}
  .member-ft {
    overflow: hidden;
    .member-logout {
      height: 25px;
      margin-top: -25px;
      transition: none 0s ease 0s;
      .btn-login {
        width: 92px;
      }
      a {
        float: left;
        line-height: 25px;
        width: 75px;
        margin-right: 7px;
        text-align: center;
        color: #FFF;
        background-image: -webkit-linear-gradient(left,#ff5000 0,#ff6f06 100%);
        background-image: -o-linear-gradient(left,#ff5000 0,#ff6f06 100%);
        background-image: linear-gradient(to right,#ff5000 0,#ff6f06 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff5000', endColorstr='#ffff6f06', GradientType=1);
        -webkit-border-radius: 4px;
        -webkit-background-clip: padding-box;
        -moz-border-radius: 4px;
        -moz-background-clip: padding;
        border-radius: 4px;
        background-clip: padding-box;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .member-login {
      a {
        float: left;
        margin-left: -1px;
        text-align: center;
        border-left: 1px solid #FFF;
        color: #3C3C3C;
      }
      .member-column-4 {
        display: inline-block;
        a {
          width: calc(50% - 40px);
          padding: 30px 20px;
          box-shadow: 2px 2px 2px -2px rgb(95 101 105 / 15%);
          &:nth-child(3), &:nth-child(4) {
            box-shadow: 1px 0 1px -1px rgb(95 101 105 / 15%);
          }
          strong {
            height: 18px;
            display: block;
            font-size: 14px;
            color: #F40;
          }
          &:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}
</style>
