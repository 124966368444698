<template>
  <router-link class="g-col clearfix" :to="{path: link, query: {id: item.id}}" :class="{'box-bottom' : isBoxBottom, 'clear-left': rowFirstItem}" :style="'width: ' + width + ';'">
    <img :src="item.image"/>
    <div class="g-pack-bg"></div>
    <span class="g-pack-rt" v-if="item.status === 'active'">
      <span class="g-pack-tag g-pack-tag-red"><i class="icon-live"></i>直播中</span>
    </span>
    <!--            <span class="g-pack-rb">-->
    <!--              <span>更新至第8章</span>-->
    <!--            </span>-->
    <p class="g-pack-title">
      {{item.name}}
    </p>
    <div class="bottom clearfix">
      <span class="price">{{formatPrice(item.price) || "免费"}}</span>
      <span class="learn">{{item.learnNum || item.signUpNum || 0}}人报名</span>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: "RectangleIndex",
    props: {
      item: {
        type: Object
      },
      isBoxBottom: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "200px"
      },
      rowFirstItem: {
        type: Boolean,
        default: false
      },
      link: {
        type: String,
        default: "/learn/detail"
      }
    },
    setup() {
      const formatPrice = (p) => {
        if (!p) {
          p = 0
        }
        if (p === 0) {
          return "免费"
        }
        return p.toFixed(2);
      }
      return {
        formatPrice
      }
    }
  }
</script>

<style scoped lang="scss">
.box-bottom {
  margin-bottom: 20px;
}
.g-col {
  position: relative;
  background: #FFFFFF;
  border-radius: 4px;
  float: left;
  margin: 10px;
  box-shadow: 0 1px 2px rgb(100 100 100 / 10%);
  &:hover {
    transform: translateY(-2px);
  }
  img {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 4px 4px 0 0;
    height: 152px;
  }
  .g-pack-rt {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0,0,0,0.5);
    color: #FFFFFF;
    padding: 0 5px;
    border-radius: 2px;
    .icon-live {
      display: inline-block;
      vertical-align: text-bottom;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      background: url("../../../assets/live.gif");
    }
  }
  .g-pack-title {
    word-break: break-all;
    color: #545C63;
    line-height: 20px;
    height: 40px;
    margin-bottom: 8px;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }
  .bottom {
    font-size: 12px;
    color: #9199A1;
    line-height: 18px;
    padding: 0 8px;
    margin-bottom: 18px;
    .price {
      line-height: 20px;
      margin-right: 2px;
      color: $--color-primary;
      float: left;
      font-weight: 700!important;
    }
    .learn {
      float: right;
    }
  }
  .clearfix:after {
    content: '\0020';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.clear-left {
  clear: left;
}
</style>
