<template>
  <el-dialog custom-class="login-dialog" :model-value="show" :show-close="showClose" :before-close="close" :modal="true" :close-on-press-escape="false" :close-on-click-modal="false">
    <!--    <div class="corner-icon-view view-type-qr-code" :class="{ 'view-type-pc': menuType === 'qrCode' }">-->
    <!--      <i @click="selectMenu('3')" class="iconfont icon-qr-code" :class="{ 'icon-pc': menuType === 'qrCode' }"/>-->
    <!--    </div>-->
    <div class="login-content" style="padding-top: 40px;">
      <div class="login-password">
        <div v-if="menuType !== 'qrCode'">
          <el-menu :default-active="menuActiveIndex" class="el-menu" mode="horizontal" @select="selectMenu">
            <el-menu-item index="1">短信登录</el-menu-item>
            <el-menu-item index="2">账号登录</el-menu-item>
          </el-menu>
          <div v-if="menuType === 'authCode'">
            <el-form :model="authCodeForm" ref="login-form" class="login-form">
              <el-input v-model="authCodeForm.mobile" placeholder="请输入手机号码" class="input-text username input-with-select"/>
              <div class="input-text input-wrap-password">
                <el-input v-model="authCodeForm.authCode" placeholder="请输入验证码">
                  <template #append>
                    <el-button @click="getAuthCode(authCodeAppendValue)">{{authCodeAppendValue}}</el-button>
                  </template>
                </el-input>
              </div>
              <div class="forgot-password-links">
                <a target="_blank"/>
              </div>
            </el-form>
          </div>
          <div v-if="menuType === 'password'">
            <el-form :model="passwordForm" ref="login-form" class="login-form">
              <el-input v-model="passwordForm.username" class="input-text username" placeholder="手机/邮箱"/>
              <div class="input-wrap-password">
                <el-input v-model="passwordForm.password" :type="passwordType" class="input-text" placeholder="登录密码" maxlength="40"/>
                <div class="password-look-btn" @click="showPasswordChange">
                  <i class="iconfont" :class="{
                    'icon-eye-close': !showPassword,
                    'icon-eye-open': showPassword
                  }"/>
                </div>
              </div>
              <div class="forgot-password-links">
                <a target="_blank" href="/forget/pwd">忘记密码</a>
              </div>
            </el-form>
          </div>
          <div class="login-btn">
            <el-button v-loading="loginLoading" @click="login">登录</el-button>
          </div>
          <div class="agreement-login">
            <div class="agreement">
              <div style="text-align:center;">点击登录或完成账号注册表示您已阅读并同意本站
                <a href="/agreement/service" target="_blank" style="margin-left:0;color:#24A5FF;">《服务协议》</a>和
                <a href="/agreement/privacy" target="_blank" style="margin-left:0;color:#24A5FF;">《隐私政策》</a>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="login-qr-code">
          <div id="ding-talk-body" v-show="oAuth2Type === 'ding-talk'" style="margin: 0 auto;width: 300px;height: 400px"></div>
          <div id="work-we-chat-body" v-show="oAuth2Type === 'work-we-chat'" style="margin: 0 auto;width: 300px;height: 400px"></div>
          <div class="custom-qr-code" v-show="oAuth2Type === ''">
            <div class="login-tip">
              <div>
                打开手机<strong>App</strong>
                <br>
                在「我的」页面右上角打开扫一扫
              </div>
            </div>
            <div class="qr-code-img">
              <div><img src="" alt="敬请期待" height="165" width="165"></div>
            </div>
          </div>
        </div>
        <div class="sns-login-block" v-if="menuType !== 'qrCode'">
          <div class="sns-login">
            <!--            <div class="sns-login-title">其他方式登录</div>-->
            <!--            <div class="oauth2-login">-->
            <!--              <el-tooltip effect="dark" content="钉钉登录" placement="top">-->
            <!--                <a @click="selectOauth2('ding-talk')" class="oauth2-login-icon icon-dingtalk"/>-->
            <!--              </el-tooltip>-->
            <!--              <el-tooltip effect="dark" content="企业微信登录" placement="top">-->
            <!--                <a @click="selectOauth2('work-we-chat')" class="oauth2-login-icon icon-workwechat"/>-->
            <!--              </el-tooltip>-->
            <!--            </div>-->
            <a href="/register" target="_blank" class="registerLink">还没有账号？注册</a>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {reactive, ref} from "vue";
  import {authCodeLogin, getMsgAuthCode, passwordLogin, getWorkWeChatConfig, getDingTalkConfig} from "../api/login";
  import {error, info, success} from "@/util/tipsUtils";
  import {setToken} from "@/util/tokenUtils";

  export default {
    name: "LoginIndex",
    emits: ["success", "callback"],
    props: {
      show: Boolean,
      callback: {
        type: Function,
        default: () => {}
      },
      success: {
        type: Function,
        default: () => {}
      },
      showClose: {
        type: Boolean,
        default: true,
        required: false
      }
    },
    setup(props, context) {
      const loginLoading = ref(false)
      const menuActiveIndex = ref("2");
      const menuType = ref("password");
      const showPassword = ref(false);
      const passwordType = ref("password");
      const authCodeAppendLabel = "获取验证码";
      const authCodeAppendValue = ref("获取验证码");
      const loginQrCodeUrl = ref("");
      const passwordForm = reactive({
        username: "charronlay@sina.com",
        password: "123456"
      });
      const authCodeForm = reactive({
        mobile: "",
        authCode: ""
      });
      // 关闭组件回调
      const close = () => {
        context.emit("callback");
      };
      // 选择登录方式
      const oAuth2Type = ref("");
      const selectMenu = index => {
        menuActiveIndex.value = index;
        switch (menuActiveIndex.value) {
        // 验证码
        case "1":
          menuType.value = "authCode";
          break;
        // 密码
        case "2":
          menuType.value = "password";
          break;
        // 扫码
        case "3":
          if (menuType.value === "qrCode") {
            menuType.value = "authCode";
            menuActiveIndex.value = "1";
            oAuth2Type.value = ""
          } else {
            menuType.value = "qrCode";
            // 获取二维码
            // getLoginQrCode(res => {
            //   console.log(res);
            //   // 二维码地址
            //   loginQrCodeUrl.value = res.data;
            // });
          }
          break;
        }
      };
      const selectOauth2 = (type) => {
        oAuth2Type.value = type
        menuType.value = "qrCode";
        const schema = window.location.protocol
        const host = window.location.host
        let redirectUri = schema + "//" + host + "/ding-talk"
        switch (type) {
        case "ding-talk":
          getDingTalkConfig((res) => {
            const url = "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" + res.appId + "&response_type=code&scope=snsapi_login&state=STATE"
            window.DDLogin({
              id: "ding-talk-body",
              goto: encodeURIComponent(url + "&redirect_uri="+ redirectUri),
              style: "border:none;background-color:#FFFFFF;",
              width: "300",
              height: "400"
            });
            const handleMessage = function (event) {
              const origin = event.origin;
              console.log("origin", event.origin);
              //判断是否来自ddLogin扫码事件。
              if (origin === "https://login.dingtalk.com") {
                const loginTmpCode = event.data;
                //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
                window.location.href = url + "&redirect_uri="+ encodeURIComponent(redirectUri) + "&loginTmpCode=" + loginTmpCode
              }
            };
            if (typeof window.addEventListener != "undefined") {
              window.addEventListener("message", handleMessage, false);
            } else if (typeof window.attachEvent != "undefined") {
              window.attachEvent("onmessage", handleMessage);
            }
          })
          break;
        case "work-we-chat":
          getWorkWeChatConfig((res) => {
            window.WwLogin({
              "id": "work-we-chat-body",
              "appid": res.appId,
              "agentid": res.agentId,
              "redirect_uri": encodeURIComponent(schema + "//" + host + "/work-we-chat"),
              "state": res.state,
              "href": ""
            })
          })
          break;
        }
      }
      // 显示密码
      const showPasswordChange = () => {
        showPassword.value = !showPassword.value;
        if (showPassword.value) {
          passwordType.value = "text";
        } else {
          passwordType.value = "password";
        }
      };
      // 获取验证码
      const getAuthCode = value => {
        if (value !== authCodeAppendLabel) {
          return;
        }
        if (!authCodeForm.mobile) {
          error("请输入手机号码");
          return;
        }
        // 获取验证码
        getMsgAuthCode(authCodeForm.mobile, () => {
          let times = 60;
          const timer = setInterval(() => {
            times--;
            if (times === 0) {
              authCodeAppendValue.value = authCodeAppendLabel;
              clearInterval(timer);
            } else {
              authCodeAppendValue.value = times + "";
            }
          }, 1000);
          info("暂不发送短信，统一验证码为：123456");
        });
      };
      // 登录方法
      const login = () => {
        loginLoading.value = true
        if (menuType.value === "password") {
          passwordLogin(passwordForm, res => {
            loginLoading.value = false
            success("登录成功");
            const accessToken = { expiresIn: res.expires_in, value: res.access_token };
            const refreshToken = res.refresh_token;
            const data = { accessToken: accessToken, refreshToken: refreshToken };
            // 保存登录信息
            setToken(data)
            // 成功后回调
            context.emit("success");
            close();
          }).catch(() => {
            loginLoading.value = false
          })
        } else if (menuType.value === "authCode") {
          authCodeLogin(authCodeForm, (res) => {
            loginLoading.value = false
            success("登录成功");
            const accessToken = { expiresIn: res.expires_in, value: res.access_token };
            const refreshToken = res.refresh_token;
            const data = { accessToken: accessToken, refreshToken: refreshToken };
            // 保存登录信息
            setToken(data)
            // 登录成功回调
            context.emit("success");
            // 关闭窗口回调
            close();
          }).catch(() => {
            loginLoading.value = false
          })
        }
      };
      return {
        // 变量
        menuActiveIndex,
        menuType,
        showPassword,
        passwordType,
        authCodeAppendValue,
        loginQrCodeUrl,
        passwordForm,
        authCodeForm,
        oAuth2Type,
        // 方法
        close,
        selectMenu,
        showPasswordChange,
        getAuthCode,
        login,
        selectOauth2,
        loginLoading
      };
    }
  };
</script>

<style lang="scss">
  .login-dialog {
    width: 424px !important;
    height: 507px;
    background-color: #fff;
    //background-image: url("../assets/login/login-bg.png")!important;
    background-size: 424px 507px!important;
    background-repeat: no-repeat;
    background-position: top;
    padding-bottom: 13px;
    max-width: 424px;
    margin: 0 auto;
    border-radius: 8px!important;
    .el-dialog__header {
      padding: 0;
      .el-dialog__headerbtn {
        font-size: 20px;
        top: 10px;
        z-index: 999999;
      }
    }
    .corner-icon-view {
      position: relative;
      width: 100%;
      margin: 0 auto;
      height: 48px;
    }
    .corner-icon-view.view-type-qr-code:before {
      content: "";
      display: inline-block;
      background: url("../assets/login/scan-qrcode-tips.png");
      background-size: contain;
      width: 132px;
      height: 28px;
      position: absolute;
      top: 7px;
      right: 57px;
      z-index: 1;
    }
    .corner-icon-view.view-type-pc:before {
      background: none;
    }
    .iconfont {
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: block;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 40px;
      color: #ff9000;
      width: 60px;
      height: 60px;
      text-indent: -99999px;
      background-size: contain;
    }
    .icon-qr-code {
      background-image: url("../assets/login/qr-code-icon.png");
    }
    .icon-pc {
      background-image: url("../assets/login/pc-icon.png");
    }
    .el-dialog__body {
      padding: 0;
    }
    .login-content {
      margin: 0 auto;
      padding: 0 48px 0;
      .el-menu {
        background: rgba(255, 255, 255, 0);
        border: none;
        li {
          width: 50%;
          height: 42px;
          line-height: 42px;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #999;
        }
        li:hover,
        li:focus {
          background: rgba(255, 255, 255, 0);
          color: #999;
        }
        li.is-active {
          color: $--color-primary;
          border-color: $--color-primary;
        }
      }

      .username {
        margin-top: 34px;
        input {
          margin-bottom: 10px;
        }
      }
      .input-text {
        input {
          height: 42px;
          outline: none;
          width: 100%;
          background: none;
          border: none;
          border-bottom: 1px solid #d8d8d8;
          font-size: 14px;
          padding-left: 0;
          color: #000;
          border-radius: 0;
        }
        .el-input-group__append {
          height: auto;
        }
      }
      .input-wrap-password {
        position: relative;
        font-size: 14px;
      }
      .password-look-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        .iconfont {
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 20px;
          display: block;
          background-size: contain;
          width: 24px;
          height: 24px;
        }
        .icon-eye-close {
          background-image: url("../assets/login/eye-close.png");
        }
        .icon-eye-open {
          background-image: url("../assets/login/eye-open.png");
        }
      }

      .forgot-password-links {
        text-align: right;
        zoom: 1;
        margin: 12px 0;
        line-height: 18px;
        a {
          display: inline-block;
          cursor: pointer;
          margin-left: 10px;
          font-size: 13px;
          color: #ccc;
          /*color: #666;*/
        }
        a:hover {
          color: $--color-primary;
        }
      }
      .login-btn {
        width: 100%;
        button {
          outline: none;
          color: #fff;
          width: 100%;
          cursor: pointer;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border: none;
          border-radius: 30px;
          font-size: 16px;
          background-image: linear-gradient(90deg, #8833ff, $--color-primary);
          padding: 0;
        }
      }
      .agreement-login {
        zoom: 1;
        text-align: center;
        font-size: 13px;
        margin: 12px 0;
        color: #666;
        .agreement {
          margin-bottom: 8px;
          font-size: 13px;
          line-height: 18px;
          a {
            text-decoration: none;
            display: inline-block;
            font-size: 13px;
            margin-left: 0;
            color: $--color-primary;
          }
        }
      }

      .sns-login-block {
        zoom: 1;
        text-align: center;
        position: relative;
        height: 122px;
        margin: 20px 0 0;
        .sns-login {
          margin: 10px 0;
          float: none;
          .sns-login-title {
            font-size: 13px;
            line-height: 18px;
            color: #666666;
          }
          .sns-login-title:before,
          .sns-login-title:after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 1px;
            margin: 0 12px;
            vertical-align: middle;
            background-color: #3b3b42;
          }

          .oauth2-login {
            float: none;
            /*display: flex;*/
            justify-content: space-between;
            margin-top: 20px;
            a {
              margin-left: 0;
            }
            .oauth2-login-icon {
              background-size: contain;
              background-position: 0 0;
              display: inline-block;
              background-repeat: no-repeat;
              width: 36px;
              height: 36px;
              margin: 0 20px;
            }
            .icon-taobao {
              background-image: url("../assets/login/taobao.png");
            }
            .icon-alipay {
              background-image: url("../assets/login/alipay.png");
            }
            .icon-weixin {
              background-image: url("../assets/login/weixin.png");
            }
            .icon-qq {
              background-image: url("../assets/login/qq.png");
            }
            .icon-weibo {
              background-image: url("../assets/login/weibo.png");
            }
            .icon-dingtalk {
              background-image: url("../assets/login/dingtalk.png");
            }
            .icon-workwechat {
              background-image: url("../assets/login/workwechat.png");
            }
          }
        }
        a:hover {
          color: $--color-primary;
        }
        a {
          text-decoration: none;
          display: inline-block;
          margin-left: 10px;
          cursor: pointer;
        }
        .registerLink,
        .forgotLoginIdLink {
          /*position: absolute;*/
          bottom: 0;
          font-size: 14px;
          line-height: 20px;
          color: #999;
        }
        .registerLink {
          // left: -30px;
          margin: 20px auto 0;
        }
        .forgotLoginIdLink {
          right: -30px;
        }
      }
    }
    .el-input-group__prepend {
      border: none;
      width: 86px !important;
    }
    .el-input-group__prepend,
    .el-input-group__append {
      border-radius: 0 !important;
      width: 66px;
      background: rgba(255, 255, 255, 0);
      height: 42px;
      border-right: none;
      border-top: none;
      border-left: none;
      margin-bottom: 10px;
      padding: 0;
      .el-select {
        height: 42px;
        margin: 0 0 10px;
        input {
          border: none;
          margin: 0;
        }
      }
      .el-input__suffix-inner {
        height: 20px;
        border-right: 1px solid #eee;
      }
      .el-input--suffix {
        height: 42px;
        border-bottom: 1px solid #ccc;
      }
      .el-select:hover,
      .el-select:focus {
        color: #000;
        border-bottom: 1px solid #ccc;
      }
    }
    .el-input-group__append {
      text-align: center;
    }
    .login-qr-code {
      text-align: center;
      zoom: 1;
      .custom-qr-code{
        margin-top: 30px;
        margin-bottom: 40px;
      }
      .login-tip {
        color: #999;
        font-size: 14px;
        line-height: 22px;
        strong {
          color: $--color-primary;
        }
      }
      .qr-code-img {
        position: relative;
        margin: 20px auto;
        font-size: 14px;
        -webkit-box-shadow: 0 0 8px #ddd;
        opacity: 1;
        width: 165px;
        height: 165px;
        box-shadow: none;
      }
    }
  }
</style>
