<template>
  <div class="announcement-main">
    <div class="announcement-box">
      <div class="announcement-title">公告</div>
      <div class="announcement-list" v-loading="listLoading">
        <el-carousel v-if="announcementList && announcementList.length" height="20px" direction="vertical" :autoplay="true" :interval="8000">
          <el-carousel-item v-for="item in announcementList" :key="item">
            <div class="announcement-item" @click="goto('/announcement/detail', item.id)">{{item.title}}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="announcement-more">
        <router-link :to="{path: '/announcement'}" target="_blank">
          查看更多
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
import {getAnnouncementList} from "@/api/announcement";
import router from "@/router";
export default {
  name: "OneAnnouncement",
  components: {
  },
  setup() {
    const params = ref({
      current: 1,
      size: 5
    })
    const listLoading = ref(true)
    const announcementList = ref([])
    const total = ref(0)
    const loadList = () => {
      listLoading.value = true
      getAnnouncementList(params.value, res => {
        announcementList.value = res.list
        total.value = res.total
        listLoading.value = false
      }).catch(() => {
        listLoading.value = false
      })
    }
    loadList()
    const goto = (path, id) => {
      if (id) {
        router.push({ path: path, query: { id: id } })
      } else {
        router.push({ path })
      }
    }
    return {
      announcementList,
      params,
      total,
      listLoading,
      goto,
    }
  }
}
</script>

<style scoped lang="scss">
.announcement-main {
  margin: 0 0 20px 0;
  .announcement-box {
    border: 1px solid #f2f2f2;
    margin: 0 10px;
    padding: 10px 0;
    display: flex;
    .announcement-title {
      display: inline-block;
      font-weight: 500;
      width: 30px;
      margin-right: 20px;
      padding-left: 20px;
    }
    .announcement-list {
      display: inline-block;
      width: calc(100% - 120px);
      .announcement-item {
        display: inline-block;
        cursor: pointer;
        &:hover {
          color: $--color-primary;
        }
      }
      ::v-deep .el-carousel__indicators {
        display: none;
      }
    }
    .announcement-more {
      color: #999;
      display: inline-block;
      font-size: 12px;;
      width: 50px;
      margin-left: 20px;
      text-align: right;
      line-height: 21px;
      padding-right: 20px;
      a {
        color: #999999;
        &:hover {
          color: $--color-primary;
        }
      }
    }
  }
}
</style>
