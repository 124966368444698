import storage from "./storageUtils";

function hasAuthority(authorityObjs, route) {
  if (route.meta && route.meta.authorities) {
    return authorityObjs.some(authorityObj => route.meta.authorities.includes(authorityObj))
  } else {
    return true
  }
}

export function filterAsyncRoutes(routes, authorities) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasAuthority(authorities, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, authorities)
      }
      res.push(tmp)
    }
  })
  return res
}

export function deleteAuthorities() {
  storage.remove("authorityList");
}

export function setAuthorities(authorities) {
  deleteAuthorities();
  storage.setJson("authorityList", authorities);
}

export function getAuthorities() {
  const authorities = storage.getJson("authorityList");
  if (authorities) {
    return authorities.authorities
  }
  return null;
}

export function checkAuthorities(value) {
  if (value && value instanceof Array && value.length > 0) {
    const authorities = getAuthorities();
    const authoritiesAttrs = value
    return authorities.some(authorityObj => {
      return authoritiesAttrs.includes(authorityObj)
    })
  } else {
    console.error("need authorities! Like ['admin']")
    return false
  }
}

export function getRoutes() {
  return storage.getJson("routes");
}

export function setRoutes(routes) {
  storage.remove("routes");
  storage.setJson("routes", routes);
}


