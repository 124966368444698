import SvgIcon from "@/components/SvgIcon"
// 全局注册组件
export default (app) => {
  // 定义一个加载目录的函数
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  const req = require.context("@/assets/svg", false, /\.svg$/);
  // 加载目录下的所有 svg 文件
  requireAll(req);
  app.use(SvgIcon);
};

