import {del, get, post, put} from "../../util/requestUtils"

export function findList(params, success) {
  return get("/content/public-api/article/list", params, success)
}

export function getArticle(id, success) {
  return get("/content/public-api/article", {id: id}, success)
}

export function findTopList(params, success) {
  return get("/content/public-api/article/top/list", params, success)
}

export function findRecommendList(params, success) {
  return get("/content/public-api/article/recommend/list", params, success)
}

export function countMemberArticle(success) {
  return get("/content/public-api/article/member/count", {}, success)
}

export function saveArticle(data, success) {
  return post("/content/auth-api/article", data, success)
}

export function updateArticle(data, success) {
  return put("/content/auth-api/article", data, success)
}

export function removeArticle(data, success) {
  return del("/content/auth-api/article", data, success)
}

export function getMemberArticleList(params, success) {
  return get("/content/auth-api/member/article/list", params, success)
}

export function getListByIds(params, success) {
  return get("/content/public-api/article/list/by-ids", params, success)
}
