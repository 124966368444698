<template>
  <div class="news-wrapper">
    <div class="module-header">
      <div class="head">
        <h2 class="head-title">
          <div class="title">
            <span style="margin-right: 5px;">
              <svg-icon name="ask"/>
            </span>
            <router-link :to="{path: 'ask'}">问答</router-link>
          </div>
        </h2>
      </div>
    </div>
    <div v-if="!(questions && questions.length && hotQuestions && hotQuestions.length)">
      <el-empty/>
    </div>
    <el-row v-else :gutter="0">
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="question-good" v-loading="questionLoading">
          <div class="recommend-list">
            <div class="recommend-item" v-for="item in questions" :key="item.id">
              <div class="title-box">
                <router-link :to="{path: '/ask/question', query: {id: item.id}}" style="padding-left: 0" class="title" target="_blank">{{item.title}}</router-link>
              </div>
              <router-link :to="{path: '/ask/question', query: {id: item.id}}" class="intro" target="_blank">{{item.content}}</router-link>
              <div class="meta">
                <el-image :src="item.member.avatar.trim()" alt="" class="avatar">
                  <template #error>
                    <div class="image-slot">
                    </div>
                  </template>
                </el-image>
                <span class="name">{{item.member.name}}</span>
                <span class="time">{{item.createTime}}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
        <!--        <div class="module-header">-->
        <!--          <div class="head">-->
        <!--            <h2 class="head-title">-->
        <!--              <div class="title"><router-link :to="{path: 'ask'}"></router-link></div>-->
        <!--            </h2>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="question-hot" v-loading="hotQuestionLoading" :class="{'bg': !(hotTopQuestion && hotTopQuestion.id) && !(hotQuestions && hotQuestions.length)}">
          <div class="wgt-discussion-list">
            <router-link :to="{path: '/ask/question', query: {id: hotTopQuestion.id}}" target="_blank" class="discussion-big">
              <el-image v-if="hotTopQuestion.image" :src="hotTopQuestion.image.trim()">
                <template #error>
                  <div class="image-slot">
                  </div>
                </template>
              </el-image>
              <div class="mask"></div>
              <div class="text-container">
                <div class="title">{{hotTopQuestion.title}}</div>
                <div class="intro" v-html="hotTopQuestion.content"></div>
                <div class="statistics" v-if="hotTopQuestion.member">
                  <img :src="hotTopQuestion.member.avatar" alt="" class="avatar">
                  <span class="name">{{hotTopQuestion.member.name}}</span>
                  <span class="point"></span>
                  <span class="thumb-num">{{hotTopQuestion.likeNum || 0}}点赞</span>
                  <div class="discuss-num">{{hotTopQuestion.answerNum || 0}}条热门回答</div>
                </div>
              </div>
            </router-link>
            <div class="discussion-small-list">
              <router-link :to="{path: '/ask/question', query: {id: item.id}}" target="_blank" class="discussion-small" v-for="item in hotQuestions" :key="item.id">
                <el-image class="img" :src="item.image">
                  <template #error>
                    <div class="image-slot">
                    </div>
                  </template>
                </el-image>
                <div class="mask"></div>
                <div class="text-container">
                  <div class="title">{{item.title}}</div>
                  <div class="discuss-num">{{item.answerNum || 0}}条热门回答</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {ref} from "vue";
import {getQuestionList} from "@/api/ask";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "QuestionIndex",
  components: {SvgIcon},
  setup() {
    const questions = ref([])
    const params = ref({
      current: 1,
      size: 3
    })
    const questionLoading = ref(true)
    // 获取问题列表
    const loadQuestionList = () => {
      questionLoading.value = true
      questions.value = []
      getQuestionList(params.value, res => {
        questions.value.push(...res.list)
        questionLoading.value = false
      }).catch(() => {
        questionLoading.value = false
      })
    }
    loadQuestionList()
    // 热议
    const hotQuestions = ref([])
    const hotTopQuestion = ref({})
    const hotParams = ref({
      current: 1,
      size: 6
    })
    const hotQuestionLoading = ref(true)
    // 获取问题列表
    const loadHotQuestionList = () => {
      hotQuestionLoading.value = true
      hotQuestions.value = []
      getQuestionList(hotParams.value, res => {
        if (res.list && res.list.length) {
          // hotTopQuestion.value = res.list[0]
          // if (res.list.length > 1) {
          //   const l = res.list.splice(1, res.list.length)
          //   hotQuestions.value.push(...l)
          // }
          hotQuestions.value.push(...res.list)
        }
        hotQuestionLoading.value = false
      }).catch(() => {
        hotQuestionLoading.value = false
      })
    }
    loadHotQuestionList()
    return {
      questionLoading,
      questions,
      hotQuestionLoading,
      hotQuestions,
      hotTopQuestion
    }
  }
}
</script>

<style scoped lang="scss">
.news-wrapper {
  margin-left: auto;
  margin-right: auto;
  height: inherit;
  margin-bottom: 10px;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  .module-header {
    display: flex;
    margin-bottom: 10px;
    .head {
      line-height: 40px;
      height: 40px;
      display: inline-block;
      &:before {
        content: " ";
        display: table;
      }
      .head-title {
        margin-top: 20px;
        margin-left: 10px;
        font-size: 20px;
        float: left;
        margin-right: 12px;
        transform: translateY(-50%);
        color: #222;
        a {
          color: #222;
        }
      }
      &:after {
        clear: both;
        content: " ";
        display: table;
      }
    }
  }
  .question-hot {
    padding: 0;
    border-radius: 4px;
    margin: 0 10px;
    .wgt-discussion-list {
      width: 100%;
      height: 100%;
      display: flex;
      .discussion-big {
        display: inline-block;
        position: relative;
        height: 364px;
        width: calc(40% - 20px);
        margin-right: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 100%;
        border-radius: 4px;
        display: none;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
        &:hover {
          top: -1px;
          box-shadow: 0 2px 2px 0 rgb(158 172 182 / 60%);
        }
        .mask {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 199px;
          border-radius: 0 0 4px 4px;
          background-image: linear-gradient(
                  -180deg
              ,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
        }
        .text-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 16px;
          box-sizing: border-box;
          color: #FFF;
          .title {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-shadow: 0 1px 0 #000;
          }
          .intro {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-shadow: 0 1px 0 #000;
          }
          .statistics {
            margin-bottom: 14px;
            font-size: 12px;
            color: rgba(255,255,255,.8);
            line-height: 12px;
            .avatar {
              width: 24px;
              height: 24px;
              margin-right: 4px;
              vertical-align: middle;
              border-radius: 24px;
            }
            .name {
              margin-right: 4px;
              vertical-align: middle;
            }
            .point {
              display: inline-block;
              width: 2px;
              height: 2px;
              margin-right: 4px;
              border-radius: 2px;
              vertical-align: middle;
              background: rgba(255,255,255,.8);
            }
            .thumb-num {
              vertical-align: middle;
            }
            .discuss-num {
              float: right;
              height: 24px;
              line-height: 24px;
            }
          }
        }
      }
      .discussion-small-list {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        min-height: 364px;
        .discussion-small {
          display: inline-block;
          position: relative;
          width: calc(33.3333% - 13.3333px);
          height: 172px;
          margin: 10px;
          border-radius: 4px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center 100%;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(-n+3) {
            margin-top: 0;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          .img {
            height: 100%;
            width: 100%;
            border-radius: 4px;
            background: #f5f5f5;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            top: -1px;
            box-shadow: 0 2px 2px 0 rgb(158 172 182 / 60%);
          }
          .mask {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 73px;
            border-radius: 0 0 4px 4px;
            background-image: linear-gradient(
                    -180deg
                ,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
          }
          .text-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            color: #FFF;
            .title {
              margin-bottom: 8px;
              font-size: 14px;
              color: #FFF;
              line-height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .discuss-num {
              margin-bottom: 10px;
              font-size: 12px;
              color: rgba(255,255,255,.8);
              line-height: 12px;
            }
          }
        }
      }
    }
  }
  .question-hot.bg {
    background: #fff;
  }
  .question-good {
    //padding: 20px;
    background: #fff;
    border-radius: 4px;
    margin: 10px;
    height: 364px;
    .recommend-list {
      .recommend-item {
        margin-bottom: 15px;
        border-bottom: 1px solid #EEE;
        min-height: 100px;
        .title-box {
          position: relative;
          height: 28px;
          .title {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            margin-bottom: 10px;
            width: 100%;
            font-size: 16px;
            color: #333;
            line-height: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            box-sizing: border-box;
            padding-left: 0;
            &:hover {
              color: $--color-primary;
            }
          }
        }
        .intro {
          margin-bottom: 8px;
          font-size: 12px;
          color: #666;
          line-height: 24px;
          text-decoration: none;
          display: -webkit-box;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          min-height: 36px;
        }
        .meta {
          margin-bottom: 15px;
          line-height: 1;
          .avatar {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            vertical-align: middle;
            border-radius: 20px;
          }
          .name {
            font-size: 12px;
            color: rgba(153,153,153,.8);
            line-height: 12px;
          }
          .time {
            float: right;
            display: inline-block;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: rgba(153,153,153,.8);
          }
        }
        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
          .meta {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
