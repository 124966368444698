import {del, get, post, put} from "@/util/requestUtils"

// 获取问题列表
export const getQuestionList = (params, success) => {
    return get("/ask/public-api/question/list", params, success)
}

// 提出问题
export function saveQuestion(data, success) {
    return post("/ask/auth-api/question", data, success)
}

export function updateQuestion(data, success) {
  return put("/ask/auth-api/question", data, success)
}

// 获取分类列表
export const getCategoryList = (params, success) => {
    return get("/ask/public-api/category/list", params, success)
}

// 获取回答列表
export const getAnswerList = (params, success) => {
    return get("/ask/public-api/answer/list", params, success)
}

// 获取问题详情
export const getQuestion = (params, success) => {
    return get("/ask/public-api/question", params, success)
}

// 提出回答
export function saveAnswer(data, success) {
    return post("/ask/auth-api/answer", data, success)
}

export function countMemberQuestion(params, success) {
  return get("/ask/public-api/question/member/count", params, success)
}

export function countMemberAnswer(params, success) {
  return get("/ask/public-api/answer/member/count", params, success)
}

// 获取会员问题列表
export const getMemberQuestionList = (params, success) => {
  return get("/ask/auth-api/member/question/list", params, success)
}

// 获取会员答案列表
export const getMemberAnswerList = (params, success) => {
  return get("/ask/auth-api/member/answer/list", params, success)
}

export function removeQuestion(id, success) {
  return del("/ask/auth-api/question", {id: id}, success)
}

export function removeAnswer(id, success) {
  return del("/ask/auth-api/answer", {id: id}, success)
}

export function updateAnswer(data, success) {
  return put("/ask/auth-api/answer", data, success)
}

export function getQuestionListByIds(params, success) {
  return get("/ask/public-api/question/list/by-ids", params, success)
}

export function getAnswerListByIds(params, success) {
  return get("/ask/public-api/answer/list/by-ids", params, success)
}

