<template>
  <div class="footer">
    <div class="wrapper">
      <div class="content">
        <el-row>
          <el-col :span="24" class="category-col">
            <ul class="category-list">
              <li v-for="item in categoryList" :key="item.name">
                <dl class="category">
                  <dt>{{item.name}}</dt>
                  <dd v-for="subItem in item.children" :key="subItem.name">
                    <a :href="subItem.href" target="_blank">{{subItem.name}}</a>
                  </dd>
                </dl>
              </li>
              <li>
                <dl class="category">
                  <dt>
                    联系我们
                  </dt>
                  <dd>
                    <div class="contact">
                      <a class="online" target="_blank" :href="'tencent://message/?uin=' + contact.qq + '&Site=qq&Menu=yes'">
                        <p>
                          QQ(<span>{{contact.qq}}</span>)
                        </p>
                      </a>
                      <div class="phone-box">
                        <p>
                          Tel(<span class="phone">{{contact.mobile}}</span>)
                        </p>
                      </div>
                    </div>
                  </dd>
                </dl>
              </li>
              <li style="width: 294px;">
                <dl class="category" style="margin: 0;">
                  <dt>
                    <i class="el-icon-circle-plus-outline icon"></i>
                    关注我们
                  </dt>
                  <dd>
                    <ul class="code-list">
                      <li v-for="item in contact.qrCodeList" :key="item.text">
                        <div class="qr-code" v-if="item.qrCode && item.qrCode.text">
                          <img :src="item.qrCode.imgUrl">
                          <span>{{item.qrCode.text}}</span>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="bottom">
        <div class="copyright-box">
          <p class="copyright">
            Copyright © 2023 米度Family内部平台 版权所有
            <span class="copyright-line"> | </span>
            <span class="vp-foot-copyright-main">
              <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备00000000号</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "FooterIndex",
    setup() {
      const contact = {
        mobile: "13610210010",
        qq: "2509745",
        qrCodeList: [
          {
            text: "微信",
            href: "",
            icon: "qq",
            qrCode: {
              text: "微信",
              imgUrl: require("../assets/contact-us/wechat.png")
            }
          },
          {
            text: "微信公众号",
            href: "",
            icon: "official-account",
            qrCode: {
              text: "公众号",
              imgUrl: require("../assets/contact-us/official-account.png")
            }
          }
        ]
      }
      const categoryList = [
        {
          name: "网站协议",
          children: [
            {
              name: "服务协议",
              href: "/agreement/service"
            },
            {
              name: "隐私政策",
              href: "/agreement/privacy"
            },
            {
              name: "侵权投诉",
              href: "/agreement/tort"
            }
          ]
        },
        {
          name: "产品",
          children: [
            {
              name: "在线学习平台",
              href: "http://web.chawind.com"
            }
          ]
        },
        {
          name: "开源社区",
          children: [
            {
              name: "码云",
              href: "https://gitee.com/yuanjiusheng/cloud-learning-ce"
            },
            {
              name: "Github.com",
              href: "https://github.com/yuanjiusheng/cloud-learning-ce"
            }
          ]
        }
      ]
      return {
        contact,
        categoryList
      }
    }
  }
</script>
<style scoped lang="scss">
  .footer {
    margin-top: 20px;
    .wrapper {
      min-width: 960px;
      padding-top: 40px;
      -webkit-tap-highlight-color: transparent;
      font-size: 0;
      line-height: 1;
      position: relative;
      color: #FFFFFF;
      background-color: #000000;
      .content {
        margin: 0 auto;
        position: relative;
        max-width: 1400px;
        min-width: 960px;
        .category-col {
          padding: 0!important;
        }
        .category-list {
          display: inline-table;
          vertical-align: top;
          padding: 0;
          margin: 0 10px;
          width: calc(100% - 20px);
          li {
            display: table-cell;
            .category {
              margin: 0 80px 30px 0;
              border-bottom-color: #45474d;
              font-size: 0;
              dt {
                font-size: 16px;
                line-height: 18px;
                padding-bottom: 20px;
              }
              dd {
                color: #ffffff;
                font-size: 14px;
                line-height: 18px;
                margin: 0 0 18px 0;
                a {
                  color: inherit;
                  text-decoration: none;
                  display: inline;
                  -webkit-transition: color .4s;
                  -o-transition: color .4s;
                  -moz-transition: color .4s;
                  transition: color .4s;
                }
                a:hover {
                  color: $--color-primary;
                }
                .code-list {
                  list-style-type: none;
                  margin: 0;
                  padding: 0;
                  li {
                    .qr-code {
                      margin-right: 20px;
                      img {
                        display: inline-block;
                        width: 140px;
                        height: 140px;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                      }
                      span {
                        display: block;
                        font-size: 12px;
                        color: #ffffff;
                        width: 100%;
                        text-align: center;
                      }
                    }
                    &:last-child {
                      .qr-code {
                        margin: 0;
                      }
                    }
                  }
                }
                .contact {
                  color: #c2c5cc;
                  .online {
                    color: inherit;
                    text-decoration: none;
                    display: inline-block;
                    margin-bottom: 20px;
                    line-height: 18px;
                    font-size: 14px;
                    -webkit-transition: color .4s ease-in-out;
                    -o-transition: color .4s ease-in-out;
                    -moz-transition: color .4s ease-in-out;
                    transition: color .4s ease-in-out;
                    position: relative;
                    p {
                      font-size: 14px;
                      position: relative;
                      color: #ffffff;
                      line-height: 18px;
                    }
                    span {
                      color: #FFFFFF;
                      font-size: 14px;
                    }
                  }
                  .phone-box {
                    p {
                      font-size: 14px;
                      position: relative;
                      color: #ffffff;
                      line-height: 18px;
                    }
                    span {
                      color: #FFFFFF;
                      font-size: 14px;
                    }
                  }
                  .icon {
                    margin-right: 2px;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
      .bottom {
        margin-top: 20px;
        white-space: normal;
        color: #fff;
        background-color: #000;
        .copyright-box {
          font-size: 12px;
          line-height: 16px;
          padding: 14px 0;
          margin: 0 auto;
          max-width: 1400px;
          min-width: 960px;
          border-top: solid 1px rgba(255, 255, 255, 0.2);
          .copyright {
            margin: 0 10px;
            a {
              color: inherit;
              text-decoration: none;
              display: inline;
            }
          }
        }
      }
    }
  }
</style>
