<template>
  <el-empty v-if="!itemList || !itemList.length" description="暂无数据" style="background: #ffffff;width: 100%;margin: 10px 0;border-radius: 4px;"></el-empty>
  <div v-for="item in itemList" :key="item.id" class="content-item" @click="gotoDetail(item)">
    <div class="image-wrap" v-if="item.image">
      <el-image :src="item.image"></el-image>
    </div>
    <div class="box-content">
      <div class="title" v-html="item.title"></div>
      <div class="desc" v-html="escapeHTML(item.introduction)"></div>
      <div class="other">
        <div class="time">{{item.createTime}}</div>
        <div class="price" v-if="tagsFormat(item.tags) && tagsFormat(item.tags).length">
          <span class="tag-lab">标签：</span>
          <div class="tag" v-for="t in tagsFormat(item.tags)" :key="t">
            {{t}}
          </div>
        </div>
        <div class="user-info-box">
          <div class="image-name" v-if="item.member">
            <img :src="item.member.avatar" alt="">
            <span>{{item.member.name}}</span>
          </div>
        </div>
      </div>
      <div class="user-info" v-if="showMember">
        <div class="downloads">{{item.downloadNum || 0}}次已下载</div>
      </div>
      <div class="box-bottom" v-else>
        <div class="downloads">
          <span>{{item.downloadNum || 0}}次已下载</span>
          <span>收藏 {{item.favoriteNum || 0}}</span>
          <span>点赞 {{item.likeNum || 0}}</span>
          <span>评论 {{item.commentNum || 0}}</span>
        </div>
        <div class="btn" v-if="editable">
          <el-button type="text" class="action float-right" @click.stop="showForm(item)">编辑</el-button>
          <el-button type="text" class="action float-right" @click.stop="remove(item)">删除</el-button>
        </div>
      </div>
    </div>
  <!--    <edit :item="editItem" v-if="dialogVisible" v-model="dialogVisible" :cancel-callback="cancelForm" :submit-callback="submitCallback"></edit>-->
  </div>
</template>

<script>
import {ref} from "vue";
import router from "@/router";
import {confirm, success} from "@/util/tipsUtils";
import {deleteResource} from "@/api/resource";
export default {
  name: "ResourceItem",
  components: {
    // edit
  },
  props: {
    itemList: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: false
    },
    showMember: {
      type: Boolean,
      default: true
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const gotoDetail = (item) => {
      router.push({path: "/resource/detail", query: {id: item.id}})
    }
    const dialogVisible = ref(false)
    const editItem = ref();
    const showForm = (item) => {
      // dialogVisible.value = true
      editItem.value = item
      router.push({path: "/resource/edit", query: {id: item.id, topath: '/member/resource'}})
    }
    const cancelForm = () => {
      dialogVisible.value = false
    }
    const submitCallback = () => {
      cancelForm()
      props.callback && props.callback()
    }
    const remove = (item) => {
      confirm("确认删除该条资源吗？", "提示", () => {
        deleteResource({id: item.id}, () => {
          success("删除成功")
          props.callback && props.callback()
        })
      }, () => {
      })
    }
    const escapeHTML = function(untrusted) {
      // Escape untrusted input so that it can safely be used in a HTML response
      // in HTML and in HTML attributes.
      return untrusted
          .replace(/<img.*>/gi, '')
          .replace(/<video.*>/gi, '')
          // .replace(/&/g, '&amp;')
          // .replace(/</g, '&lt;')
          // .replace(/>/g, '&gt;')
          // .replace(/"/g, '&quot;')
          // .replace(/'/g, '&#39;');
    }
    const tagsFormat = function (tags) {
      console.log(tags)
      if (tags && tags.length) {
        return tags.split(",")
      }
      return []
    }
    return {
      gotoDetail,
      showForm,
      cancelForm,
      submitCallback,
      remove,
      editItem,
      dialogVisible,
      escapeHTML,
      tagsFormat
    }
  }
}
</script>

<style lang="scss" scoped>
.content-item {
  background-color: #FFFFFF;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  border-top: 1px solid #f2f2f2;
  &:first-child {
    border-top: 0;
  }
  &:hover {
    .title {
      color: $--color-primary;
    }
  }
  .el-image {
    width: 180px;
    height: 100%;
    border-radius: 5px;
    margin-right: 20px;
  }
  .image-wrap {
    width: 180px;
    height: 100px;
    padding-right: 10px;
  }
  .box-content {
    flex: 1;
    .title {
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      line-height: 28px;
    }
    .desc {
      font-size: 12px;
      color: #999999;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
      margin-top: 2px;
      line-height: 24px;
    }
    .other {
      font-size: 14px;
      color: #19be6b;
      height: 28px;
      line-height: 28px;
      .price {
        display: inline-block;
        .tag-lab {
          color: #999999;
          font-size: 12px;
        }
        .tag {
          font-size: 12px;
          display: inline-block;
          margin-right: 10px;
          color: #999999;
          border-radius: 4px;
        }
      }
      .time {
        font-size: 14px;
        color: #999;
        display: inline-block;
        margin-right: 20px;
      }
    }
    .user-info-box {
      display: inline-block;
      float: right;
      margin: 0 10px;
      .image-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          margin-right: 10px;
        }
      }
    }
    .user-info {
      display: flex;
      display: none;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 5px;
      border-top: 1px solid #f2f2f2;
      padding-top: 6px;
      .downloads {
        color: #999;
      }
      ::v-deep img {
        width: 100%;
      }
    }
    .box-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      margin-top: 5px;
      padding-top: 6px;
      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          color: #222222;
          &:hover {
            color: $--color-primary;
          }
        }
      }
      .downloads {
        color: #999;
        span {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
